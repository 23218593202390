import {
  Component,
  Input,
  ChangeDetectorRef,
  HostListener,
  SimpleChanges,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { Group } from 'src/app/interfaces/Group';
import { BaseComponent } from '../../base/base.component';
import { GroupService } from 'src/app/services/repositories/group.service';
import { PostService } from 'src/app/services/repositories/post.service';
import { AuthService } from 'src/app/services/utilities/auth.service';
import { UiStateService } from 'src/app/services/utilities/ui-state.service';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { ScreenSizeService } from 'src/app/services/utilities/screen-size.service';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent extends BaseComponent {
  environment = environment;
  apiUrl = environment.apiUrl;
  selectedGroup: number = 0;
  highlightedText: string = '';
  groupScopes: number[] = [];
  data: any = [];
  // authenticatedUser!: any;
  accreditedGroups: any[] = [];
  //get all subgroup
  windowWidth: number = window.innerWidth;
  isMobileView: boolean = false;
  screenHeight: number = 0;
  screenWidth: number = 0;

  constructor(
    translationService: TranslationService,
    public postService: PostService,
    private groupService: GroupService,
    private authService: AuthService,
    private uiStateService: UiStateService,
    private cdr: ChangeDetectorRef,
    private eRef: ElementRef,
    private renderer: Renderer2,
    private screenSizeService: ScreenSizeService
  ) {
    super(translationService);
    this.checkWindowSize();
  }
  caption!: string;
  AccreditedGroups: any[] = [];
  uiMaps: any[] = [];
  UserAccreditations!: any;
  isUserSuperAdmin!: boolean;
  @Input() auth!: any;
  groupsFlatList: any = [];
  rootGroups: any;
  initialData: any = [];
  allAccreditationsData: any = [];
  uiStoredData: any;
  ngOnInit() {
    const lsData = this.postService.getGroupScopesFromLocalStorage();
    this.postService.updateGroups(lsData)
    this.authService.loadCurrentUser().subscribe((user: any) => {
      this.UserAccreditations = user.data.accreditations; //get accreditation

      this.isUserSuperAdmin = user.data.isAdmin;

      // this.groupsFlatList = this.UserAccreditations;

      this.groupsFlatList = this.getAccreditedGroups();
      if (!this.isUserSuperAdmin) {
        //user is not super admin
        this.data = this.UserAccreditations.map((ac: any) => ac.group);
        // Set group to first group in user accreditations
        this.postService.updateGroup(this.UserAccreditations[0].group);
        this.groupsFlatList = this.groupsFlatList.map(
          (item: any) => item.group
        );
        //build group tree
        this.groupTree = this.groupsFlatList.map((item: any) => {
          return {
            ...item,
            isOpen: false,
            isVisible: item.ancestry.length == 1,
            step: item.ancestry.length - 1,
            childrenIds: this.selectChildren(item),
            level: (item.ancestry.length - 1) * 10,
          };
        });
        this.sortGroupTree(this.groupTree);
        this.allAccreditationsData = this.groupTree.map((item: any) => {
          return {
            ...item,
            isOpen: false,
            isVisible: true,
            step: item.ancestry.length - 1,
            level: (item.ancestry.length - 1) * 10,
          };
        });

        this.initialData = [...this.groupTree]; // Ensure a deep copy
        if(lsData.length>0){
          this.groupScopes = lsData
        }else{
          this.toggleSelectAll();
        }

      }else {
        //user is super admin
        this.groupService.getStructuredGroups().subscribe((groups: any) => {
          this.data = Array.isArray(groups.data) ? groups.data : []; // Ensure it's an array
          for (let group of groups.data) {
            this.UserAccreditations.push({ group });
          }
          this.groupsFlatList = this.UserAccreditations.filter(
            (acc: any) => acc.profile != undefined
          ).map((acc: any) => acc.group);
          //sorting
          const sortedList = this.sortByAncestry([...this.groupsFlatList]);

          this.groupTree = this.groupsFlatList.map((item: any) => {
            return {
              ...item,
              isOpen: false,
              isVisible: item.ancestry.length == 1,
              step: item.ancestry.length - 1,
              childrenIds: this.selectChildren(item),
              level: (item.ancestry.length - 1) * 10,
            };
          });
          this.sortGroupTree(this.groupTree);

          // Set initialData after data is fetched
          this.initialData = [...this.groupTree]; // Ensure a deep copy
          this.allAccreditationsData = this.groupTree.map((item: any) => {
            return {
              ...item,
              isOpen: false,
              isVisible: true,
              step: item.ancestry.length - 1,
              level: (item.ancestry.length - 1) * 10,
            };
          });
          // Transform data after fetch
          this.data = this.data.map((g: any) => {
            return {
              ...g,
              size: 0,
              ml: '',
            };
          });
          if(lsData.length>0){
            this.groupScopes = lsData
          }else{
            this.groupScopes = [this.groupTree[0].id]
          }
          
          this.caption = this.updateCaption();
        });
      }
      
      this.caption = this.updateCaption();
    });
    this.screenSizeService.screenWidth$.subscribe((width: number) => this.screenWidth = width);
    this.screenSizeService.screenHeight$.subscribe((height: number) => this.screenHeight = height);
    console.log('this.screenHeight',this.screenHeight);
    console.log('this.screenWidth',this.screenWidth);
  }

  groupTree: any = [];
  private sortByAncestry(groups: any[]) {
    return groups.sort((a, b) => {
      if (a.ancestry.length !== b.ancestry.length) {
        return a.ancestry.length + b.ancestry.length;
      } else {
        for (let i = 0; i < a.ancestry.length; i++) {
          if (a.ancestry[i] !== b.ancestry[i]) {
            return a.ancestry[i].localeCompare(b.ancestry[i]);
          }
        }
        return 0;
      }
    });
    
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.windowWidth = window.innerWidth;
    if (this.windowWidth < 1110) this.isMobileView = true;
    else this.isMobileView = false;
  }

  private checkWindowSize() {
    this.windowWidth = window.innerWidth;
  }

  trackByGroupId(index: number, accg: any): number {
    return accg.group.id;
  }

  getAccreditedGroups() {
    if (this.isUserSuperAdmin) {
      return [];
    }
    const accg: any = [];
    for (let accreditation of this.UserAccreditations) {
      function recurseGroups(group: any, profile: any) {
        // Push the current group and profile to the result
        accg.push({ group: group, profile: profile });

        // Recursively process each child group if they exist
        if (group.children && group.children.length > 0) {
          for (let child of group.children) {
            recurseGroups(child, profile);
          }
        }
      }

      if (accreditation.applyToSubGroups) {
        recurseGroups(accreditation.group, accreditation.profile);
      } else {
        accg.push({
          group: accreditation.group,
          profile: accreditation.profile,
        });
      }
    }

    return accg;
  }

  socialMediaOptions: any[] = [];
  selectedSocialMedia: Number = 0;

  onGroupChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    const groupId = selectElement.value;
    this.selectedGroup = +groupId; // Ensure groupId is a number
    this.cdr.detectChanges(); // Force change detection

    let accGroups = this.UserAccreditations.map((acc: any) => acc.group);
    let groups = this.flattenGroupsFromAccreditations(accGroups);


    const group = groups.find((g: any) => g.id === this.selectedGroup);

    this.postService.updateGroup(group);

    this.socialMediaOptions = group ? group.socialMedia : [];
    this.postService.socialMediaOptions.next(group ? group.socialMedia : []);
    this.postService.selectedSocialMedia.next(group?.socialMedia);

    if (group?.socialMedia) {
      this.postService.googleExists.next(
        group.socialMedia.some((sm: any) =>
          sm.source.toLowerCase().includes('google')
        )
      );
      this.postService.linkedInExists.next(
        group.socialMedia.some((sm: any) =>
          sm.source.toLowerCase().includes('linkedin')
        )
      );
      this.postService.facebookExists.next(
        group.socialMedia.some((sm: any) =>
          sm.source.toLowerCase().includes('facebook')
        )
      );
      this.postService.instagramExists.next(
        group.socialMedia.some((sm: any) =>
          sm.source.toLowerCase().includes('instagram')
        )
      );
    } else {
      this.postService.googleExists.next(false);
      this.postService.linkedInExists.next(false);
      this.postService.facebookExists.next(false);
      this.postService.instagramExists.next(false);
    }
  }

  getCurrentGroupId() {
    return this.postService.currentGroupSource.getValue().id;
  }

  flattenGroupsFromAccreditations(groups: any[]): any[] {
    let groupResults = [];
    for (let group of groups) {
      groupResults.push(group);

      if (group.children.length > 0) {
        groupResults = [
          ...groupResults,
          ...this.flattenGroupsFromAccreditations(group.children),
        ];
      }
    }
    return groupResults;
  }

  searchPattern: string = '';
  isDropdownOpen = false;

  toggleDropdown(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
    if (this.isDropdownOpen) {
      this.renderer.listen('document', 'click', (event) => {
        if (!this.eRef.nativeElement.contains(event.target)) {
          this.isDropdownOpen = false;
        }
      });
    }
  }

  searchItems(searchTerm: string): void {
    if (searchTerm === '') {
      this.groupTree = this.initialData;
      return;
    }
    // const groupsFlatListmapped = this.groupTree.map((item: any) => item);

    // const regex = new RegExp(searchTerm, 'i');
    // this.groupTree = this.allAccreditationsData.filter((d: any) =>
    //   regex.test(d.name)
    // );
    const regex = new RegExp('(' + searchTerm + ')', 'gi');
    this.highlightedText = searchTerm; // Stocke le terme de recherche pour le surlignage

    this.groupTree = this.allAccreditationsData.map((item: any) => {
      const itemName = item.name.replace(
        regex,
        `<span class="bg-yellow-200">${searchTerm}</span>`
      );
      return { ...item, highlightedName: itemName };
    });
  }

  toggleOpen(item: any, event: any): void {

    event.stopPropagation();
    //select item if it has no children
    if (item.children.length == 0) {
      this.toggleSelected(item, event);
    }

    item.children.forEach((element: any) => {
      const i = this.groupTree.find((e: any) => e.id == element.id);
      if (i) {
        this.itemVisible(i);

        if (item.isOpen && item.children.length > 0) {
          const childIds = this.getAllChildIds(item);
          this.groupTree.forEach((child: any) => {
            if (childIds.includes(child.id)) {
              child.isVisible = child.isOpen = false;
            }
          });
          //find recursivly all children and
        }
        // i.isOpen = item.isOpen;
        // item.isVisible = item.isVisible;
      }
    });
    item.isOpen = !item.isOpen;
    return;
  }

  getAllChildIds(item: any): number[] {
    const collectIds = (children: any[]): number[] => {
      let ids: number[] = [];
      children.forEach((child: any) => {
        ids.push(child.id);
        if (child.children && child.children.length > 0) {
          ids = ids.concat(collectIds(child.children));
        }
      });

      return ids;
    };

    return collectIds(item.children);
  }

  getPlatformProperty(source: string, property: any) {
    // const platform = this.SOCIAL_MEDIA_PLATFORMS.find(
    // (p) => p.source === source
    // );
    return ' platform ? platform[property] : null';
  }

  public toggledItems: { [key: number]: boolean } = {};

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && this.data.length != 0) {
      this.initialData = JSON.parse(JSON.stringify(this.data));
    }
  }

  setAsCurrentGroup(item: any, event: any) {
		// console.log("setAsCurrentGroup");
    //update only if this.groupScopes and this.postService.currentGroupsSource.getValue() not the same
    const currentGroups = this.postService.currentGroupsSource.getValue()
		// console.log("JSON.stringify(this.groupScopes)", JSON.stringify(this.groupScopes))
		// console.log("JSON.stringify( currentGroups)", JSON.stringify( currentGroups))
    // if(JSON.stringify(this.groupScopes)!=JSON.stringify( currentGroups)){
      this.postService.updateGroups(this.groupScopes);
      this.caption = this.updateCaption();
    // }
    this.toggleDropdown();
    return;
  }

  itemVisible(item: any) {
    item.isVisible = !item.isVisible;
  }

  toggleSelected(item: any, event: Event): void {

    if (item.children.length > 0) {
      if (this.groupScopes.includes(item.id)) {
        this.groupScopes = this.groupScopes.filter(
          (id: number) => !item.childrenIds.includes(id)
        );
        this.groupScopes = this.groupScopes.filter(
          (id: number) => !item.ancestryIds.includes(id)
        );
      } else {
        this.groupScopes = this.groupScopes.concat(item.childrenIds);
        this.groupScopes = this.groupScopes.concat(item.id);
      }
    } else {
      if (this.groupScopes.includes(item.id)) {
        this.groupScopes = this.groupScopes.filter(
          (id: number) => id !== item.id
        );
        this.groupScopes = this.groupScopes.filter(
          (id: number) => !item.ancestryIds.includes(id)
        );
      } else {
        this.groupScopes.push(item.id);
      }
    }

    for (let i = this.groupTree.length - 1; i >= 0; i--) {

      if (this.groupTree[i].childrenIds.length > 0) {
        if (
          this.groupTree[i].childrenIds.every((e: any) =>
            this.groupScopes.includes(e)
          )
        ) {
          this.groupScopes.push(this.groupTree[i].id);
        }
      }
      if (this.groupScopes.includes(this.groupTree[i].id)) {
        this.groupTree[i].isOpen = true;
        this.groupTree[i].isVisible = true;
      }
    }
    this.groupScopes = [...new Set([...this.groupScopes])];
    this.postService.saveGroupScopesToLocalStorage(this.groupScopes);
  }

  toggleSelectAll(): void {
    if (this.groupTree.length == this.groupScopes.length) {
      this.groupScopes = [];
    } else {
      const ids = this.groupTree.map((item: any) => item.id);
      const uniqueScopes = new Set([...this.groupScopes, ...ids]);
      this.groupScopes = Array.from(uniqueScopes);
      this.groupTree = this.allAccreditationsData;
    }
    this.postService.updateGroups(this.groupScopes)
    this.postService.saveGroupScopesToLocalStorage(this.groupScopes);

  }

  sortGroupTree(items: any[]) {
    items.forEach((group, index) => {
      if (group.children.length > 0) {
        let i = 1;
        group.children.forEach((child: { id: any }) => {
          let ind = items.findIndex((item) => item.id === child.id);
          items.splice(index + i, 0, items.splice(ind, 1)[0]);
          i++;
        });
      }
    });
  }

  selectChildren(item: any): number[] {
    let children: number[] = [];
    for (const node of item.children) {
      children.push(node.id);
      if (node.children.length > 0) {
        children = children.concat(this.selectChildren(node));
      }
    }
    return children;
  }

  selectParent(item: any): number[] {
    let parent: number[] = [];
    parent.push(item.parentId);
    this.groupTree.forEach((group: { id: any }) => {
      if (item.parentId == group.id)
        parent = parent.concat(this.selectParent(group));
    });
    return parent;
  }

  isAllSelected() {
    return this.groupScopes.length == this.groupTree.length;
  }

  updateCaption():string {
    let result= '';
    let i = 0;
    for (const element of this.groupTree) {
      if (
        this.groupScopes.includes(element.id) &&
        i < this.groupScopes.length &&
        i < 2
      ) {
        result += element.name + ', ';
        i++;
      }
      if (i == 2) break;
    }
    if (i == 2 && this.groupScopes.length > 2) {
      if (this.groupScopes.length - 2 == 1)
        result =
          result + this.getTranslation('generic.and')+' ' + (this.groupScopes.length - 2) +' '+ this.getTranslation('generic.other');
      if (this.groupScopes.length - 2 > 1)
        result =
          result + this.getTranslation('generic.and')+' ' + (this.groupScopes.length - 2) +' '+ this.getTranslation('generic.others');
          
    }
    if (i <= 2 && this.groupScopes.length <= 2)
      result = result.slice(0, -2);

    return result;
  }

}