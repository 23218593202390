import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CalenderBaseComponent } from 'src/app/components/base/calendar.component';
import { PostService } from 'src/app/services/repositories/post.service';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { UiStateService } from 'src/app/services/utilities/ui-state.service';
import { CalendarService } from 'src/app/services/utilities/calendar.service';

@Component({
  selector: 'app-post-label-preview',
  templateUrl: './post-label-preview.component.html',
  styleUrls: ['./post-label-preview.component.scss'],
})
export class PostLabelPreviewComponent extends CalenderBaseComponent {
  @Input() posts: any;
  @Input() hour: number = 0;
  @Input() day: string = '';
  @Input() screenWidth: number = 0
  @Input() screenHeight: number = 0
  @Output() labelClicked = new EventEmitter();
  previewIndex: number = 0;

  constructor(
    protected override uiStateService: UiStateService,
    protected override postService: PostService,
    protected override translationService: TranslationService,
    protected override calendarService: CalendarService
  ) {
    super(translationService, calendarService, postService, uiStateService);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    // Debug.log("postLabel data",this.day,this.hour,this.posts)
  }

  hasManyLabels(): boolean {
    if (this.posts && this.posts.length > 0) {
    }
    //Debug.log("***",this.posts.length,"**",this.posts[0]?.expectedPublishingDatetime.split("T")[1]?.split(":")[0],this.hour,this.day)

    return this.posts && this.posts.length > 1;
  }
  isPostPlatformInFilter(posts: any): boolean {
    // First, check if any of the platform filters is ON
    if (
      this.currentPlaTformsFilters.isInstagramON ||
      this.currentPlaTformsFilters.isGoogleON ||
      this.currentPlaTformsFilters.isLinkedinON ||
      this.currentPlaTformsFilters.isFacebookON
    ) {
      // Now, check each post
      return posts.some((post: any) => {
        let isCurrentPostPlanned = post.expectedPublishingDatetime
          ? true
          : false;
        let isCurrentPostPublished = post.publishingDatetime ? true : false;

        // Checking if the post's platform is among those that are ON, and if the post status matches the filter (planned or published)
        return post.SocialPlatforms.some(
          (platform: { source: string; id: number }) => {
            let platformName = platform.source.toLowerCase();
            let isPlatformOn =
              this.currentPlaTformsFilters[
                `is${
                  platformName.charAt(0).toUpperCase() + platformName.slice(1)
                }ON`
              ];
            return (
              isPlatformOn &&
              ((isCurrentPostPlanned && this.isPlanned) ||
                (isCurrentPostPublished && this.isPublished))
            );
          }
        );
      });
    }

    return false;
  }
  next() {
    if (this.posts.length > 1)
      this.previewIndex =
        this.previewIndex + 1 > this.posts.length - 1
          ? 0
          : this.previewIndex + 1;
  }

  isPostOfThisHour(posts: any[]): boolean {
    return posts.some((post: any) => {
      let dateString =
        post.expectedPublishingDatetime !== null
          ? post.expectedPublishingDatetime
          : post.publishingDatetime;
      let localTime = this.utcToCurrentTimeZone(new Date(dateString));

      //  let isHourSameAsLocalTime:boolean = parseInt(localTime.split(" ")[1].split(":")[0]) === this.hour
      //  let isHourSameAsDateString:boolean = parseInt(dateString.split(" ")[1].split(":")[0]) === this.hour
      //  Debug.log("localtime",localTime,"dateString",dateString)
      //Debug.log("localtime",localTime,"dateString",dateString,"hour",this.hour,"h same as localTime?",isHourSameAsLocalTime,"h same as dateString ",isHourSameAsDateString)
      return parseInt(localTime.split(' ')[1].split(':')[0]) === this.hour;
    });
  }
}
