<div class="flex flex-wrap pb-2 pr-1">
    <div class="bg-[#465086] ml-2 rounded-lg px-2 mb-2 h-[30%]">
        <div class="flex">
            <span class="my-1 mr-3">
                <svg width="20px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="#ffffff" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path fill="#ffffff" d="M512 160c320 0 512 352 512 352S832 864 512 864 0 512 0 512s192-352 512-352zm0 64c-225.28 0-384.128 208.064-436.8 288 52.608 79.872 211.456 288 436.8 288 225.28 0 384.128-208.064 436.8-288-52.608-79.872-211.456-288-436.8-288zm0 64a224 224 0 1 1 0 448 224 224 0 0 1 0-448zm0 64a160.192 160.192 0 0 0-160 160c0 88.192 71.744 160 160 160s160-71.808 160-160-71.744-160-160-160z"></path></g></svg>
            </span>
            <span class="text-white mr-3 my-auto text-[15px] font-semibold">{{'generic.'+type | translate}}</span>
        </div>
        <div class="bg-gray-100 rounded-md h-5 mb-2 mx-auto flex"><span class="justify-center items-center mx-auto text-[12px] font-bold  ">{{value}}</span></div>
    </div>
</div>