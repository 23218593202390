import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { jwtDecode } from 'jwt-decode';
import { JwtPayload } from '../../interfaces/JwtPayload';
import { environment } from '../../../environments/environment';
import { UserService } from '../repositories/user.service';
import { Debug } from 'src/app/utils/debug';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private apiUrl = environment.apiUrl;
  public currentUserSubject: BehaviorSubject<any | null>;
  public currentUser: Observable<any | null>;

  constructor(private http: HttpClient, private userService: UserService) {
    const token = window.localStorage.getItem('token');
    this.currentUserSubject = new BehaviorSubject<any | null>(null);
    this.currentUser = this.currentUserSubject.asObservable();

    if (token && this.isTokenValid(token)) {
      this.loadCurrentUser().subscribe();
    }
  }

  loadCurrentUser(): Observable<any | null> {
    const userId = this.connectedUserId();
    if (userId !== 0) {
      return this.userService.getUserById(userId).pipe(
        tap((user) => {
          // Debug.log(JSON.stringify(user))
          this.currentUserSubject.next(user);
        })
      );
    } else {
      this.currentUserSubject.next(null);
      return of(null);
    }
  }

  login(username: string, password: string): Observable<any> {
    const loginData = { email: username, password: password };
    return this.http.post<any>(`${this.apiUrl}/auth/login`, loginData).pipe(
      tap((response) => {
        if (response.token) {
          localStorage.setItem('token', response.token);
          this.loadCurrentUser().subscribe((res) => {
            this.currentUser = res.data;
          });
        }
      })
    );
  }

  logout(): void {
    localStorage.removeItem('token');
    this.currentUserSubject.next(null);
  }

  isTokenValid(token: string): boolean {
    try {
      const decodedToken = jwtDecode(token) as JwtPayload;
      if (decodedToken && decodedToken.exp) {
        const currentTime = Date.now() / 1000; // seconds
        return decodedToken.exp > currentTime;
      }
    } catch (error) {
      console.error('Error validating token:', error);
    }
    localStorage.removeItem('token');
    return false;
  }

  connectedUserId(): number {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token) as JwtPayload;
      return decodedToken.userId;
    }
    return 0;
  }

  updateAuthenticatedUser(user: any) {
    // Debug.log("user UI permissions Updated ",user.UI);
    this.currentUserSubject.next(user);
  }
}
