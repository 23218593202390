<div class="bg-white rounded-3xl">
    <div class="grid grid-rows-1 grid-cols-12 mb-3">
        <span class="col-span-1">
            <span class="ml-5 text-2xl cursor-pointer" (click)="prevWeek()">←</span>
        </span>
        <span class="col-span-10 text-gray-400 font-semibold text-base w-42 px-5  mx-auto mt-2 ">
            <span>
                {{ 'generic.week' | translate}} 
                {{ 'generic.from' | translate}} 
                {{currentWeekStart.getDate().toString().padStart(2, '0')}}
                <span (click)="switchToMonthView(currentWeekStart.getMonth())" class="cursor-pointer font-semibold" *ngIf="startMonthStr!=endMonthStr">{{startMonthStr}}</span>
                {{'generic.to'|translate}} 
                {{currentWeekEnd.getDate().toString().padStart(2, '0')}} 
                <span (click)="switchToMonthView(currentWeekEnd.getMonth())" class="cursor-pointer font-semibold">{{endMonthStr}} </span>
                <span (click)="switchToYearView(currentWeekStart.getFullYear())"class="cursor-pointer font-semibold">{{currentYear}}</span>
            </span>
        </span>
        <span class="col-span-1">
            <span class="text-2xl float-right mr-5 cursor-pointer" (click)="nextWeek()">→</span>
        </span>
    </div>
    <!--week day headers -->
    <div class="" >
        <table class=" m-auto bg-gray-100  ml-[96px] rounded-tl-3xl rounded-tr-3xl border-collapse mr-[45px] pb-0 mb-0"  [style.width]="dynamicWidthStyle()" >
            <tr class="grid grid-cols-7 text-base h-16">
                <th class="flex items-center justify-center border border-1 border-gray-300 rounded-tl-3xl cursor-pointer border-collapse" (click)="switchToDay(0)">{{'generic.weekDay1'|translate}}</th>
                <th class="flex items-center justify-center border border-1 border-gray-300 cursor-pointer" (click)="switchToDay(1)">{{ 'generic.weekDay2' | translate }} <span *ngIf="">
                    <div class="badge badge-secondary">99</div>
                </span>
                </th>
                <th class="flex items-center justify-center border border-1 border-gray-300 cursor-pointer" (click)="switchToDay(2)">{{ 'generic.weekDay3' | translate }}</th>
                <th class="flex items-center justify-center border border-1 border-gray-300 cursor-pointer" (click)="switchToDay(3)">{{ 'generic.weekDay4' | translate }}</th>
                <th class="flex items-center justify-center border border-1 border-gray-300 cursor-pointer" (click)="switchToDay(4)">{{ 'generic.weekDay5' | translate }}</th>
                <th class="flex items-center justify-center border border-1 border-gray-300 cursor-pointer" (click)="switchToDay(5)">{{ 'generic.weekDay6' | translate }}</th>
                <th class="flex items-center justify-center border border-1 border-gray-300 cursor-pointer rounded-tr-3xl border-collapse" (click)="switchToDay(6)">{{'generic.weekDay0'|translate}}</th>
            </tr>
        </table>
    </div>
    <div class="calender-week m-5 mt-0 pb-5 pr-[45px] ">
        <div id="week-container" [style.height.px]="screenHeight*65/100" style="overflow-y: scroll;" >
            <table style="width: calc(100% - 5rem);" class="m-auto bg-gray-100 rounded-bl-3xl rounded-br-3xl  border-collapse mr-1">
                <tr class="grid grid-cols-7 h-24" *ngFor="let hour of generateRange(24);let i=index;">
                    <td  class="relative border border-1 border-gray-300  text-center"
                    [ngClass]="{
                        'rounded-bl-3xl':i==23
                    }"
                >
                    <span 
                        [id]="'h-'+(hour+2)"
                        class="text-sm absolute text-gray-300" 
                        style="top: 5.1rem;" 
                        [style.left]="isLocalSetToEN()? '-4.1rem':'-3.1rem'" 
                        >{{getHourLabel(hour)}}</span>                     
                        <span *ngIf="getWeekDay(0)!=''">
                            <app-post-label-preview [screenHeight]="screenHeight" [screenWidth]="screenWidth" [day]="getWeekDay(0)"  [hour]="hour" [posts]="preparePostForLabelPreviewComponent(weekPosts[getWeekDay(0)],(hour))"/>    
                        </span>
                    </td >

                    <td  class="border border-1 border-gray-300 overflow-hidden">
                        <span *ngIf="getWeekDay(1)!=''">
                            <app-post-label-preview [screenHeight]="screenHeight" [screenWidth]="screenWidth"  [day]="getWeekDay(1)" [hour]="hour" [posts]="preparePostForLabelPreviewComponent(weekPosts[getWeekDay(1)],(hour))"/>
                        </span>
                    </td>

                    <td   class="border border-1 border-gray-300 overflow-hidden">
                        <span *ngIf="getWeekDay(2)!=''">
                            <app-post-label-preview [screenHeight]="screenHeight" [screenWidth]="screenWidth" [day]="getWeekDay(2)" [hour]="hour" [posts]="preparePostForLabelPreviewComponent(weekPosts[getWeekDay(2)],(hour))"/>
                        </span>
                    </td>

                    <td  class="border border-1 border-gray-300 overflow-hidden">
                        <span *ngIf="getWeekDay(3)!=''">
                            <app-post-label-preview [screenHeight]="screenHeight" [screenWidth]="screenWidth" [day]="getWeekDay(3)" [hour]="hour" [posts]="preparePostForLabelPreviewComponent(weekPosts[getWeekDay(3)],(hour))"/>
                        </span>
                    </td>

                    <td  class="border border-1 border-gray-300 overflow-hidden">
                        <span *ngIf="getWeekDay(4)!=''">
                            <app-post-label-preview [screenHeight]="screenHeight" [screenWidth]="screenWidth" [day]="getWeekDay(4)" [hour]="hour" [posts]="preparePostForLabelPreviewComponent(weekPosts[getWeekDay(4)],(hour))"/>
                        </span>
                    </td>

                    <td   class="border border-1 border-gray-300 overflow-hidden">            
                        <span *ngIf="getWeekDay(5)!=''">
                            <app-post-label-preview [screenHeight]="screenHeight" [screenWidth]="screenWidth" [day]="getWeekDay(5)" [hour]="hour" [posts]="preparePostForLabelPreviewComponent(weekPosts[getWeekDay(5)],(hour))"/>
                        </span>
                    </td>

                    <td   class="border border-1 border-collaps border-gray-300"
                    [ngClass]="{
                        'rounded-br-3xl':i==23
                    }"
                    >
                        <span *ngIf="getWeekDay(6)!=''">
                            <app-post-label-preview [screenHeight]="screenHeight" [screenWidth]="screenWidth" [day]="getWeekDay(6)" [hour]="hour" [posts]="preparePostForLabelPreviewComponent(weekPosts[getWeekDay(6)],(hour))"/>
                        </span>
                    </td>
                </tr> 
            </table>
        </div>
    </div>
</div>
