import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-social-media-stats',
  templateUrl: './social-media-stats.component.html',
  styleUrls: ['./social-media-stats.component.scss']
})
export class SocialMediaStatsComponent {
  @Input() plateform: string = '';
  @Input() data: any = [];
  @Input() pourcentageSymbol: number = -1;

  ngOnInit(){

  }

  plateformeLogo(){
    switch(this.plateform){
      case 'linkedin':
        return 'linkedin-logo';
      case 'google':
        return 'google-logo';
      case 'facebook':
        return 'facebook-logo';
      case 'instagram':
        return 'instagram-logo';
      default:
        return '';
    }
  }

}
