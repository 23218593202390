import { Component, Input, EventEmitter, Output } from '@angular/core';
import { CalenderBaseComponent } from 'src/app/components/base/calendar.component';
import { CalendarService } from 'src/app/services/utilities/calendar.service';
import { PostService } from 'src/app/services/repositories/post.service';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { UiStateService } from 'src/app/services/utilities/ui-state.service';

@Component({
  selector: 'app-month-preview',
  templateUrl: './month-preview.component.html',
  styleUrls: ['./month-preview.component.scss'],
})
export class MonthPreviewComponent extends CalenderBaseComponent {
  constructor(
    protected override calendarService: CalendarService,
    protected override translationService: TranslationService,
    protected override postService: PostService,
    protected override uiStateService: UiStateService
  ) {
    super(translationService, calendarService, postService, uiStateService);
  }

  @Output() daySelected = new EventEmitter<any>();
  @Output() switchToMonthView = new EventEmitter<any>();
  @Input() month!: any;
  @Input() screenWidth: number = 0
  @Input() screenHeight: number = 0

  override ngOnInit() {
    //super.ngOnInit()
    const lsData = this.postService.getGroupScopesFromLocalStorage();
    this.groupScopes = lsData.length > 0 ? lsData : this.groupScopes;

    this.uiStateService.platformsFilterUpdated$.subscribe((data) => {
      // Debug.log(data)
      this.currentPlaTformsFilters = data;
    });
    this.uiStateService.postStateFilterUpdated$.subscribe((newFilter) => {
      // Debug.log('Post state filter updated:', newFilter);
      // Handle the updated filter
      this.isPublished = newFilter.isPublished;
      this.isPlanned = newFilter.isPlanned;
    });
    this.month.content = this.groupByWeeks();
  }

  goToMonth(month: number) {
    this.switchToMonthView.emit({ monthIndex: month, year: this.currentYear });
  }

  groupByWeeks() {
    let weeks = [];
    for (let i = 0; i < this.month.content.length; i += 7) {
      weeks.push([
        this.month.content[i],
        this.month.content[i + 1],
        this.month.content[i + 2],
        this.month.content[i + 3],
        this.month.content[i + 4],
        this.month.content[i + 5],
        this.month.content[i + 6],
      ]);
    }
    return weeks;
  }

  getMonthStr() {
    return this.getTranslation('generic.month' + (this.month.monthIndex + 1));
  }
  getTody(): Date {
    return this.calendarService.toDay;
  }

  getCurrentYear(): number {
    return this.calendarService.currentDate.getFullYear();
  }

  isCurrentDay(
    day: number,
    month: string,
    weeksIndex: number,
    dayIndex: number
  ): boolean {
    // Check if it's the current day
    let isDayNotFromAnotherMonth: boolean = true;

    isDayNotFromAnotherMonth = !(
      (day > 20 && dayIndex < 7 && weeksIndex < 2) ||
      (day < 15 && (weeksIndex == 5 || weeksIndex == 4))
    );
    return (
      day === this.getTody().getUTCDate() &&
      this.getCurrentYear() === this.getTody().getFullYear() &&
      this.getTodayMonthString() === month &&
      isDayNotFromAnotherMonth
    );
  }

  getTodayMonthString() {
    return new Date(2000, this.getTody().getMonth()).toLocaleString('fr-FR', {
      month: 'long',
    });
  }

  showDay(dayObj: any, weekIndex: number) {
    if (
      (dayObj.day > 15 && weekIndex == 0) ||
      (dayObj.day < 15 && (weekIndex == 4 || weekIndex == 5))
    ) {
      return;
    }

    if (dayObj.data.hasPlanned || dayObj.data.hasPublished) {
      for (let week of this.month.content) {
        for (let d of week) {
          d.isSelected = false;
        }
      }
      dayObj.isSelected = true;

      let m: number = dayObj.month + 1;
      let dateStr: string = `${this.calendarService.currentDate.getFullYear()}-${m
        .toString()
        .padStart(2, '0')}-${dayObj.day}`;

      this.postService
        .getByTimeFrame(this.groupScopes, dateStr, 'day')
        .subscribe((res) => {
          if (res.data.length > 0) {
            this.daySelected.emit({
              data: res.data,
            });
          }
        });
    }
  }

  canShowIndication(d: any, i: number, j: number): boolean {
    let showPlanned: boolean = d.data.hasPlanned && this.isPlanned;
    let showPublished: boolean = d.data.hasPublished && this.isPublished;
    let showInstagram: boolean = true;
    let showFacebook: boolean = true;
    let showGoogle: boolean = true;
    let showLinkedin: boolean = true;

    if (d.data.platforms) {
      showInstagram =
        d.data.platforms.includes('instagram') &&
        this.currentPlaTformsFilters.isInstagramON &&
        (showPlanned || showPublished);
      showFacebook =
        d.data.platforms.includes('facebook') &&
        this.currentPlaTformsFilters.isFacebookON &&
        (showPlanned || showPublished);
      showGoogle =
        d.data.platforms.includes('google') &&
        this.currentPlaTformsFilters.isGoogleON &&
        (showPlanned || showPublished);
      showLinkedin =
        d.data.platforms.includes('linkedin') &&
        this.currentPlaTformsFilters.isLinkedinON &&
        (showPlanned || showPublished);
    }

    let dayHasPlannedOrPublished: boolean =
      d.data.hasPlanned || d.data.hasPublished;
    let isDayPostsinActivePlatformFilters =
      showInstagram || showFacebook || showGoogle || showLinkedin;
    let isDayFromThisMonth = !(
      (d.day > 20 && i < 15 && j < 2) ||
      (d.day < 15 && (j == 5 || j == 4))
    );

    return (
      dayHasPlannedOrPublished &&
      isDayFromThisMonth &&
      isDayPostsinActivePlatformFilters
    );
  }
}
