import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { AuthService } from '../services/utilities/auth.service';
import { UiPermissionsService } from '../services/utilities/ui-permissions.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private uiPermissionService: UiPermissionsService
  ) {

	}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const mapName = route.data['mapName']; // Get the map name from route data
  
    return this.authService.loadCurrentUser().pipe(
      switchMap(user => {
        return this.uiPermissionService.getPermissions().pipe(
          map(uiMaps => {
            for (let uiMap of uiMaps) {
              if (uiMap.name === mapName) {
                const result = this.uiPermissionService.isActionsOnUserAccreditations(
                  uiMap,
                  user.data.accreditations,
                  user.data.isAdmin
                );
                
                if (result) {
                  return true;
                }
              }
            }
            this.router.navigate(['/posts']);
            return false;
          }),
          catchError((error) => {
            console.error("Error in permission check:", error);
            this.router.navigate(['/posts']);
            return of(false);
          })
        )
      })
    );
  }
}