      <div>
        <!-- <div class="join">
          <button type="button" class="rounded-[11px] ml-5 mb-5 mr-4  border-[2px] border-[#8E8E8E]  text-sm font-bold w-48 h-[44px] flex bg-white justify-center items-center">
            <svg width="14px" class="mr-2" height="14px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M4 12H20M12 4V20" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
            {{ 'Filtres' }}
          </button>
          <app-date-filter class="w-48 mt-[2px]"></app-date-filter>
        </div>        -->
        <div class="bg-white h-48 rounded-2xl mx-5 mb-5">
          <h3 class="font-bold ml-5 py-4 text-xl">{{'dashboard.generalAnalysis' | translate}}</h3>
          <div class="flex flex-wrap">
            <app-widget-stats *ngFor="let item of data" class="w-[14%]" [label]="item.label" [value]="item.value" [percentage]="item.percentage"/>
          </div>
        </div>        
        <div class="bg-white h-full rounded-2xl py-4 mx-5 mb-5">
          <h3 class="font-bold ml-5 py-4 text-xl">{{'dashboard.comparativeAnalysis' | translate}}</h3>
          <div class="flex flex-wrap">
            <app-social-media-stats  class="w-[24.5%]" [plateform]="'linkedin'" [data]="linkedinData" [pourcentageSymbol]="6"  ></app-social-media-stats>
            <app-social-media-stats  class="w-[24.5%]" [plateform]="'google'" [data]="googleData" ></app-social-media-stats>
            <app-social-media-stats  class="w-[24.5%]" [plateform]="'facebook'" [data]="facebookData" ></app-social-media-stats>
            <app-social-media-stats  class="w-[24.5%]" [plateform]="'instagram'" [data]="instagramData" ></app-social-media-stats>
          </div>
        </div>
        <div class="bg-white h-full rounded-2xl py-4 mx-5 mb-5">
          <h3 class="font-bold ml-5 py-4 text-xl">{{'dashboard.semanticAnalysis' | translate}}</h3>
          <img *ngIf="!false" src="/assets/img/coming soon semantique.png" alt="{{'generic.comingSoon' | translate}}" class="w-20 mx-auto">
          <div *ngIf="!false" class="mx-auto mt-5 mb-2 text-center">{{('generic.comingSoon' | translate)+" !"}}</div>
          <div *ngIf="!false" class="mx-auto mb-5 pb-2 text-center text-sm text-gray-400">{{'generic.featureUnderDevelopment' | translate}}</div>
          <div *ngIf="false" class="w-[97%] h-48 border-2 rounded-3xl border-[#465086] mx-auto flex justify-center items-center relative">
          <!-- <div class="w-[97%] h-64 border-2 rounded-3xl border-[#465086] mx-auto flex justify-center items-center relative"> -->
              <span class="absolute text-blue-600 text-xl font-semibold" style="top: 50%; left: 5%;">Génial</span>
              <span class="absolute text-pink-600 text-lg" style="top: 15%; left: 10%;">Aide</span>
              <span class="absolute text-teal-500 text-2xl font-bold" style="top: 80%; left: 15%;">Agréable</span>
              <span class="absolute text-red-400 text-sm" style="top: 5%; left: 50%;">Mitigé</span>
              <span class="absolute text-indigo-600 text-base" style="top: 65%; left: 35%;">Satisfait</span>
              <span class="absolute text-blue-400 text-sm" style="top: 45%; left: 20%;">Simple</span>
              <span class="absolute text-blue-500 text-lg" style="top: 20%; left: 25%;">Plaisant</span>
              <span class="absolute text-blue-700 text-lg font-bold" style="top: 80%; left: 55%;">Accompagnement</span>
              <span class="absolute text-pink-500 text-sm" style="top: 30%; left: 60%;">Support</span>
              <span class="absolute text-green-600 text-2xl font-bold" style="top: 10%; left: 70%;">Parfait</span>
              <span class="absolute text-blue-500 text-base" style="top: 75%; left: 85%;">Aimable</span>
              <span class="absolute text-blue-600 text-lg" style="top: 55%; left: 70%;">Super</span>
              <span class="absolute text-green-500 text-xl font-bold" style="top: 50%; left: 50%;">J’adore</span>
              <span class="absolute text-blue-500 text-base" style="top: 5%; left: 85%;">Incroyable</span>
          </div>
      </div>
      
      </div>
    
