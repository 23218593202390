import { Component, HostListener } from '@angular/core';
import { BaseComponent } from '../../base/base.component';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { PostService } from 'src/app/services/repositories/post.service';
import { UiStateService } from 'src/app/services/utilities/ui-state.service';
import { ICON } from 'src/app/interfaces/ModalIcon';
import { Debug } from 'src/app/utils/debug';
import { AuthService } from 'src/app/services/utilities/auth.service';


@Component({
  selector: 'app-posts-library',
  templateUrl: './posts-library.component.html',
  styleUrls: ['./posts-library.component.scss'],
})
export class PostsLibraryComponent extends BaseComponent {
  windowWidth: number = window.innerWidth;
  isLargeView: boolean = false;
  isMediumView: boolean = false;
  isSmallView: boolean = false;
  isVerySmallView: boolean = false;
  currentDateTime: string;

  constructor(
    translationService: TranslationService,
    protected postService: PostService,
    protected uiStateService: UiStateService,
    protected authService: AuthService
  ) {
    super(translationService);
    this.currentDateTime = new Date().toString();
    this.checkWindowSize();
  }
  posts: any = [];
  plannedPosts: any = [];

  currentPlaTformsFilters: any = {
    isFacebookON: true,
    isLinkedinON: true,
    isGoogleON: true,
    isInstagramON: true,
  };

  isPublished: boolean = true;
  isPlanned: boolean = true;
  isPost: boolean = true;
  isDraft: boolean = true;

  dateRange!: { startDate: Date; endDate: Date };
  groupScopes: number[] = [];

  user!: any;

  ngOnInit(): void {
    
    this.user = this.authService.currentUserSubject.getValue().data;

    this.groupSubscription = this.postService.currentGroups.subscribe(
      (groups) => {
        this.groupScopes = groups;
        this.SetActivePlatforms()
        this.getPosts();
        this.getDrafts();
        this.getPlanned();
      }
    );

    this.uiStateService.platformsFilterUpdated$.subscribe((data) => {
      // Debug.log(data)
      this.currentPlaTformsFilters = data;
    });

    this.uiStateService.dateRangeUpdate$.subscribe((data) => {
      this.dateRange = data;
      this.SetActivePlatforms()
      this.getPosts();
      this.getDrafts();
      this.getPlanned();
    });
    // get this month posts
    this.onResize();	
  }

  activePlatforms:string[]=[]
  SetActivePlatforms():void{
    this.activePlatforms=[]
    for(let platform in this.uiStateService.platformsFilter){
      if(this.uiStateService.platformsFilter[platform]){
        // console.log(platform.substring(2,platform.length-2).toLowerCase());
        this.activePlatforms.push(platform.substring(2,platform.length-2).toLowerCase())
      }
    }
  }
  getPosts(): void {
    const lsData = this.postService.getGroupScopesFromLocalStorage();
    this.groupScopes = lsData.length > 0 ? lsData : this.groupScopes;
    if (this.groupScopes.length > 0) {
      if (this.dateRange) {
        this.postService
          .getByDateRange(
            this.dateRange.startDate,
            new Date(this.currentDateTime),
            this.groupScopes,
            this.activePlatforms
          )
          .subscribe((res) => {
            this.posts = res.data;
						this.posts.sort((a:any, b:any) => {
							return new Date(b.publishingDatetime).getTime() - new Date(a.publishingDatetime).getTime();
						});

            Debug.log('posts', this.posts);
            // this.postService.mergePostsBySocialPlatforms(this.posts);
          });
      }
    }
  }

  getPlanned(): void {
    const lsData = this.postService.getGroupScopesFromLocalStorage();
    this.groupScopes = lsData.length > 0 ? lsData : this.groupScopes;
    if (this.groupScopes.length > 0) {
      if (this.dateRange) {
        this.postService
          .getByDateRange(
            new Date(this.currentDateTime),
            this.dateRange.endDate,
            this.groupScopes,
            this.activePlatforms
          )
          .subscribe((res) => {
            this.plannedPosts = res.data;

            Debug.log('posts', this.posts);
            // this.postService.mergePostsBySocialPlatforms(this.posts);
          });
      }
    }
  }

  drafts: any[] = [];
  getDrafts(): void {
    const lsData = this.postService.getGroupScopesFromLocalStorage();
    this.groupScopes = lsData.length > 0 ? lsData : this.groupScopes;

    if (this.groupScopes.length > 0) {
      this.postService.getDrafts(this.groupScopes,this.activePlatforms).subscribe((res) => {
        this.drafts = res.data;

        // Debug.log('drafts',this.drafts);
        // this.postService.mergePostsBySocialPlatforms(this.posts);
      });
    }
  }
  //modal data
  modalIcon = ICON.delete;
  modalHeading = 'generic.confirmDeletion';
  modalSubHeading = 'generic.confirmDraftDeletionMsg';

  currentDraft: any = null;
  confirmDraftRemoval() {
    Debug.log('draft deletion confirmed', this.currentDraft);
    this.deleteDraft(this.currentDraft);
  }
  confirmDeleteModalVisible: boolean = false;
  deleteDraft(draft: any) {
    Debug.log(`deleting draft `, draft);
    this.postService.deleteDraft(draft).subscribe((res) => {
      Debug.log(res);
      if (res.success) {
        this.postService.getDrafts(this.groupScopes).subscribe((res) => {
          this.drafts = res.data;
        });

        //change modal icon and heading and hide them after 1.5 s
        this.modalIcon = ICON.success;
        this.modalHeading = 'generic.deleted';
        this.modalSubHeading = '';
        setTimeout(() => {
          this.confirmDeleteModalVisible = false;
          //  reset them back for the next draft deletion"
          this.modalIcon = ICON.delete;
          this.modalHeading = 'generic.confirmDeletion';
          this.modalSubHeading = 'generic.confirmDraftDeletionMsg';
        }, 1500);
      }
    });
  }

  togglePost() {
    this.isPost = !this.isPost;
  }
  toggleDraft() {
    this.isDraft = !this.isDraft;
  }
  togglePlanned() {
    this.isPlanned = !this.isPlanned;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.windowWidth = window.innerWidth;

    if (1232 < this.windowWidth && this.windowWidth < 1375) {
      // Medium view
      this.isLargeView = false;
      this.isMediumView = true;
      this.isSmallView = false;
      this.isVerySmallView = false;
    } else if (1375 <= this.windowWidth && this.windowWidth < 1515) {
      // Large view
      this.isLargeView = true;
      this.isMediumView = false;
      this.isSmallView = false;
      this.isVerySmallView = false;
    } else if (this.windowWidth >= 1515) {
      // Extra large view
      this.isLargeView = false;
      this.isMediumView = false;
      this.isSmallView = false;
      this.isVerySmallView = false;
    } else if (this.windowWidth >= 1100 && this.windowWidth < 1232) {
      // Small view (windowWidth <= 1232)
      this.isLargeView = false;
      this.isMediumView = false;
      this.isSmallView = true;
      this.isVerySmallView = false;
    } else {
      this.isLargeView = false;
      this.isMediumView = false;
      this.isSmallView = false;
      this.isVerySmallView = true;
    }
  }

  private checkWindowSize() {
    this.windowWidth = window.innerWidth;
  }
}