import {
  Component,
  ElementRef,
  Input,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/utilities/auth.service';
import { ScreenSizeService } from 'src/app/services/utilities/screen-size.service';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-inner-settings',
  templateUrl: './inner-settings.component.html',
  styleUrls: ['./inner-settings.component.scss'],
})
export class InnerSettingsComponent {
  @Input() accreditedGroups!: any;
  @Input() auth!: any;
  @Input() uiMaps!: any;
  @Input() windowWidth: any;
  @Input() isMobileView: any;
  constructor(
    private eRef: ElementRef,
    private renderer: Renderer2,
    private translationService: TranslationService,
    private router: Router,
    private screenSizeService: ScreenSizeService
  ) {}
  isDropdownOpen: boolean = false;
  canAccessSocialPages: boolean = false;
  screenWidth: number = 0;
  screenHeight: number = 0;

  ngOnInit(){
    this.screenSizeService.screenWidth$.subscribe((width: number) => this.screenWidth = width);
    this.screenSizeService.screenHeight$.subscribe((height: number) => this.screenHeight = height);
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;

    // If the dropdown is open, add a click listener to the document
    if (this.isDropdownOpen) {
      this.renderer.listen('document', 'click', (event) => {
        if (!this.eRef.nativeElement.contains(event.target)) {
          this.isDropdownOpen = false;
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['uiMaps']) {
      //check permissions for all targetMaps
      const targetMap = this.uiMaps.filter(
        (m: any) => m.name == 'socialMedia.pages'
      )[0];
      // this.canAccessSocialPages = this.uiPermissionsService.isActionsOnUserAccreditations(targetMap)
    }
  }

  getTranslation(key: string): string {
    return this.translationService.t(key);
  }

  logout() {
    if (localStorage.getItem('token')) localStorage.removeItem('token');
    localStorage.removeItem(environment.sessionData);
    this.router.navigate(['/login']);
  }

  canAccess(mapName: string): boolean {
    // const targetMap = this.uiMaps.filter((m:any)=>m.name==mapName)
    // Debug.log('trying to access map', mapName,targetMap.name,'actions',targetMap.actions,this.uiMaps)
    // if(targetMap!=undefined && targetMap.actions!=undefined){
    //   Debug.log('found', targetMap)
    //   Debug.log('got action?',this.uiPermissionsService.isActionsOnUserAccreditations(targetMap))
    // }
    return true;
  }
}
