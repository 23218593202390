<div class="sm:py-2 sm:px-3   bg-white border border-[2px] border-slate-500	 rounded-xl">
    <span class="sm:flex justify-between">
        <span class="font-semibold text-sm">{{'posts.channel'|translate}} :</span> 
        <button 
            (click)="toggleLinkedIn()"
            type="button"
            [ngClass]="{
                'bg-blue-600':currentPlatforms.isLinkedinON,
                'bg-gray-100':!currentPlatforms.isLinkedinON,
                'w-[65px]': isSmallView,
                'w-[80px]': isMediumView,
                'w-[55px]': isVerySmallView,
                'w-24': isLargeView,
                'w-28': !isSmallView && !isMediumView && !isLargeView && !isVerySmallView
            }" 
             class="px-1 rounded-full ">
                <img class="w-20 m-auto" 
                [src]="currentPlatforms.isLinkedinON?'assets/img/linkedin-logo.png':'assets/img/linkedin-logo-disabled.png'" alt="linkedin">
        </button>

        <button 
            (click)="toggleGoogle()"
            type="button"
            [ngClass]="{
                'bg-orange-600':currentPlatforms.isGoogleON,
                'bg-gray-100 ':!currentPlatforms.isGoogleON,
                'w-[65px]': isSmallView,
                'w-[80px]': isMediumView,
                'w-[55px]': isVerySmallView,
                'w-24': isLargeView,
                'w-28': !isSmallView && !isMediumView && !isLargeView && !isVerySmallView
            }" 
             class="px-1 rounded-full ">
                <img class="w-16 m-auto" 
                [src]="currentPlatforms.isGoogleON ?  'assets/img/google-logo.png': 'assets/img/google-logo-disabled.png'" alt="google">
        </button>

        <button 
            (click)="toggleFacebook()"
            type="button"
            [ngClass]="{
                'bg-sky-600':currentPlatforms.isFacebookON,
                'bg-gray-100 ':!currentPlatforms.isFacebookON,
                'w-[65px] px-1': isSmallView,
                'w-[80px] px-3': isMediumView,
                'w-[55px] px-1': isVerySmallView,
                'w-24 px-4': isLargeView,
                'w-28 px-5': !isSmallView && !isMediumView && !isLargeView && !isVerySmallView
            }" 
             class="px-1 rounded-full ">
                <img class="w-16 m-auto" 
                [src]="currentPlatforms.isFacebookON? 'assets/img/facebook-logo.png': 'assets/img/facebook-logo-disabled.png'" alt="facebook">
        </button>

        <button 
            type="button"
            (click)="toggleInstagram()"
            [ngClass]="{
                'bg-pink-600':currentPlatforms.isInstagramON,
                'bg-gray-100 ':!currentPlatforms.isInstagramON,
                'w-[65px]': isSmallView,
                'w-[80px]': isMediumView,
                'w-[55px]': isVerySmallView,
                'w-24': isLargeView,
                'w-28': !isSmallView && !isMediumView && !isLargeView && !isVerySmallView
            }" 
             class="px-1 rounded-full ">
                <img class="w-16 m-auto" 
                [src]="currentPlatforms.isInstagramON?'assets/img/instagram-logo.png':'assets/img/instagram-logo-disabled.png'" alt="instagram">
        </button>

    </span>
</div>
