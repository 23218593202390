import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TranslationService } from 'src/app/services/utilities/translation.service';

@Component({
  selector: 'app-day-posts-preview',
  templateUrl: './day-posts-preview.component.html',
  styleUrls: ['./day-posts-preview.component.scss']
})
export class DayPostsPreviewComponent implements OnChanges {

  @Input() data: any;
  @Input() xpos: number = 0;
  @Input() ypos: number = 0;
  @Input() isVisible: boolean = false;
  @Output() visibilityChange = new EventEmitter<boolean>();
  @Input() previewHeight!: string; // New input for height

  constructor(private translationService: TranslationService){}
  ngOnChanges(changes: SimpleChanges) {

    if (changes['data']) {
      // Handle data changes if necessary, for example:
      this.data = this.getUniquePosts(this.data);

    }
   
  }
 

  
  close(){
    this.isVisible = false
    this.visibilityChange.emit(this.isVisible)
  }

  getUniquePosts(data: any[]): any[] {
    const uniqueCombinations = new Set();
    const uniquePosts: any[] = [];
  
    for (const item of data) {
      item.title=item.title==""||item.title==undefined?"N/A":item.title
      const combo = `${item.title}-${item.text}-${item.typePost}`;
      if (!uniqueCombinations.has(combo)) {
        uniqueCombinations.add(combo);
        uniquePosts.push(item);
      }
    }
    return uniquePosts;
  }

  getTime(item: any) {
    const date: Date = item.expectedPublishingDatetime == null ? new Date(item.publishingDatetime) : new Date(item.expectedPublishingDatetime);
    return date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0');
  }

  getDate(item: any) {
    const date: Date = item.expectedPublishingDatetime == null ? new Date(item.publishingDatetime) : new Date(item.expectedPublishingDatetime);
    return date.getDate().toString().padStart(2, '0') + ' / ' + (date.getMonth() + 1).toString().padStart(2, '0') + " / " + date.getFullYear();
  }
  getTranslation(key: string): string {
    return this.translationService.t(key);
  }
}
