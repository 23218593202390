import { Component, Output, EventEmitter, Input } from '@angular/core';
import { UiStateService } from 'src/app/services/utilities/ui-state.service';

@Component({
  selector: 'app-platform-filter',
  templateUrl: './platform-filter.component.html',
  styleUrls: ['./platform-filter.component.scss'],
})
export class PlatformFilterComponent {
  constructor(private uiStateService: UiStateService) {}

  currentPlatforms: any = {
    isFacebookON: true,
    isLinkedinON: true,
    isGoogleON: true,
    isInstagramON: true,
  };
  @Input() windowWidth: any;
  @Input() isLargeView: any;
  @Input() isMediumView: any;
  @Input() isSmallView: any;
  @Input() isVerySmallView: any;

  ngOnInit() {
    this.uiStateService.platformsFilterUpdated$.subscribe((data) => {
      // Debug.log(data)
      this.currentPlatforms = data;
    });
  }

  toggleGoogle(): void {
    this.togglePlatform('isGoogleON');
  }

  toggleFacebook(): void {
    this.togglePlatform('isFacebookON');
  }

  toggleLinkedIn(): void {
    this.togglePlatform('isLinkedinON');
  }

  toggleInstagram(): void {
    this.togglePlatform('isInstagramON');
  }

  private togglePlatform(platformKey: string): void {
    this.currentPlatforms[platformKey] = !this.currentPlatforms[platformKey];
    this.uiStateService.updatePlatformsFilter(this.currentPlatforms);
    this.uiStateService.platformsFilterUpdated.next(this.currentPlatforms);
  }
}