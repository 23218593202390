<div class="w-11/12 mx-auto mt-16">
	<div class="filters grid grid-rows-1 grid-cols-6 gap-6">
		<div class="col-span-1">
			<app-date-filter/>
		</div>
		<div class="col-span-3">
			<app-platform-filter [isSmallView]="isSmallView" [isMediumView]="isMediumView" [windowWidth]="windowWidth" [isLargeView]="isLargeView" [isVerySmallView]="isVerySmallView"/>
		</div>
		<div class="col-span-2 xs:grid-cols-1 sm:grid-cols-1">
			<div class="py-2 px-3 bg-white border border-[2px] border-slate-500 rounded-xl" >
				<span class="flex justify-between text-center">
					<span class="font-semibold text-sm">{{'posts.type'|translate}} :</span> 

					<button (click)="togglePost()" type="button" [ngClass]="{'text-white bg-dark_blue': isPost, 'bg-gray-100 text-gray-400': !isPost, 'px-5 w-[75px]': !isVerySmallView, 'px-3 w-[70px]': isVerySmallView}" 
						class="rounded-full text-sm flex justify-center items-center" 
					>
						{{ 'generic.post' | translate }}
					</button>
					<button type="button" [ngClass]="{
							'text-white bg-cliking_yellow': isPlanned,
							'bg-gray-100 text-gray-400': !isPlanned,
							'px-5 w-[75px]': !isVerySmallView, 
							'px-3 w-[70px]': isVerySmallView
						}"
						class="rounded-full text-sm flex justify-center items-center" (click)="togglePlanned()"
					>
						{{ 'generic.planned' | translate }}
					</button>
					<button type="button" [ngClass]="{
							'text-white c-b-accent-3': isDraft,
							'bg-gray-100 text-gray-400': !isDraft,
							'px-5 w-[75px]': !isVerySmallView, 
							'px-3 w-[70px]': isVerySmallView
						}"
						class="rounded-full text-sm flex justify-center items-center" (click)="toggleDraft()"
					>
						{{ 'generic.draft' | translate }}
					</button>
				</span>
			</div>
		</div>
	</div>


	<div class="mt-8 mb-5">
		<div class="columns-3 sm:columns-2 md:columns-3 lg:columns-4 xl:columns-4 2xl:columns-5 3xl:columns-6 ">
			<div *ngFor="let draft of drafts; index as i" class="masonry-item">
				<app-post-preview [user]="user" [displayAs]="'draft'" [filterValue]="isDraft" [postIndex]="i" [post]="draft" (onDeleteClicked)="confirmDeleteModalVisible=true;currentDraft=draft"></app-post-preview>
			</div>
			<div *ngFor="let plannedPost of plannedPosts; index as i" class="masonry-item">
				<app-post-preview [user]="user" [displayAs]="'planned'" [filterValue]="isPlanned" [postIndex]="i" [post]="plannedPost"></app-post-preview>
			</div>
			<div *ngFor="let post of posts; index as i" class="masonry-item">
				<app-post-preview [user]="user" [displayAs]="'post'" [filterValue]="isPost" [postIndex]="i" [post]="post"></app-post-preview>
			</div>
		</div>
	</div>
</div>

<app-modal 
	[isOpen]="confirmDeleteModalVisible" 
	[icon]="modalIcon" 
	[Heading]="modalHeading"
	[subHeading]="modalSubHeading"
	(closeModal)="confirmDeleteModalVisible=false" 
	(deleteIetm)="confirmDraftRemoval()"
></app-modal>