import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UploadResponse } from 'src/app/interfaces/UploadResponse';
import { environment } from 'src/environments/environment';
import {
  BehaviorSubject,
  Subject,
  Observable,
  catchError,
  throwError,
} from 'rxjs';
import { Group } from 'src/app/interfaces/Group';
import { Debug } from 'src/app/utils/debug';

@Injectable({
  providedIn: 'root',
})
export class PostService {
  constructor(private http: HttpClient) {}
  private apiUrl = environment.apiUrl;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    platforms:""
  };

  public googleExists: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public facebookExists: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public linkedInExists: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public instagramExists: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public socialMediaOptions: BehaviorSubject<any> = new BehaviorSubject<any>(
    []
  );
  public selectedSocialMedia: BehaviorSubject<any> = new BehaviorSubject<any>(
    []
  );
  disablePostTypeEvent = new EventEmitter();
  emitEvent(data: any) {
    this.groupsUpdeted.next(data);
  }

  private groupUpdeted = new Subject<Group>();
  groupUpdeted$ = this.groupUpdeted.asObservable();

  private groupsUpdeted = new Subject<number[]>();
  groupsUpdeted$ = this.groupsUpdeted.asObservable();
  mergePostsBySocialPlatform(posts: any[]): any[] {
    const mergedPosts: { [key: string]: any } = {};
    posts.forEach((post) => {
      // Create a unique key based on all properties except 'SocialPlatform'
      const key = this.createUniqueKey(post, ['SocialPlatform', 'id']);
      if (!mergedPosts[key]) {
        const { id, SocialPlatform, ...restOfPost } = post;
        mergedPosts[key] = { ...restOfPost, SocialPlatform: [] };
      }
      // Add the 'SocialPlatform' to the array in the merged post
      mergedPosts[key].SocialPlatform.push({
        name: post.SocialPlatform,
        postId: post.id,
      });
    });

    return Object.values(mergedPosts);
  }

  createUniqueKey(post: any, excludeKeys: string[]): string {
    return Object.entries(post)
      .filter(([key]) => !excludeKeys.includes(key))
      .map(([key, value]) => `${key}:${value}`)
      .join('|');
  }

  public currentGroupsSource: BehaviorSubject<number[]> = new BehaviorSubject<
    number[]
  >([]);

  public currentGroupSource: BehaviorSubject<Group> =
    new BehaviorSubject<Group>({
      id: 0,
      name: '',
      logoUrl: '',
      parent: null,
      ancestry: [],
      children: 0,
      socialMedia: [],
    });
  currentGroups = this.currentGroupsSource.asObservable();

  public toggledItemsSource = new BehaviorSubject<{ [key: number]: boolean }>(
    {}
  );
  public selectedSourcesSubject = new BehaviorSubject<any[]>([]);
  currentSelectedPages = this.selectedSourcesSubject.asObservable();
  currentToggledItems = this.toggledItemsSource.asObservable();

  public currentPickedDateSource: BehaviorSubject<Date | null> =
    new BehaviorSubject<Date | null>(null);
  currentPickedDate = this.currentPickedDateSource.asObservable();

  public publishNowSource: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  publishNow = this.publishNowSource.asObservable();

  SyncSelectedPagesWithCurrentSelectedPlatforms(socialMediaPagesList: any[]) {
    let selectedIds = Object.keys(this.toggledItemsSource.getValue())
      .filter((id: string) =>
        socialMediaPagesList.some((item: any) => item.id.toString() === id)
      )
      .reduce((acc: { [key: number]: boolean }, curr: string) => {
        const numKey = parseInt(curr, 10); // Convert string key back to number
        acc[numKey] = true;
        return acc;
      }, {});
  }
  //TODO: remove after implement multiple current selected groups
  updateGroup(group: any | null | undefined): void {
    if (group) {
      this.currentGroupSource.next(group);
      //this.emitEvent(group);
    } else {
      console.error('Attempted to update with undefined or null group');
      // Optionally handle or fallback to a default group state
    }
  }
  updateGroups(groups: number[]): void {
    this.currentGroupsSource.next(groups);
    this.emitEvent(groups);
  }

	updateToggledItems(toggledItems: { [key: number]: boolean }): void {
    this.toggledItemsSource.next(toggledItems);
    // Debug.log("post service toggled pages updated",toggledItems);
    // this.emitEvent(toggledItems)
  }

  updateSelectedSources(newSources: any[]): void {
    this.selectedSourcesSubject.next(newSources);
    // Debug.log("newSources",newSources);
    // this.emitEvent(newSources)
  }

  updatePickedDate(pickedDate: Date | null): void {
    this.currentPickedDateSource.next(pickedDate);
    if (pickedDate == null) {
      this.publishNowSource.next(true);
    } else {
      this.publishNowSource.next(false);
    }
  }

  updatePublishNow(value: boolean): void {
    this.publishNowSource.next(value);
  }

  getSelectedSocialMediaIds() {
    for (let key in this.toggledItemsSource.getValue()) {
      if (
        this.toggledItemsSource.getValue().hasOwnProperty(key) &&
        this.toggledItemsSource.getValue()[key] === false
      ) {
        delete this.toggledItemsSource.getValue()[key];
      }
    }
    return this.toggledItemsSource.getValue();
  }

  createPost(postData: any): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/posts`,
      postData,
      this.httpOptions
    );
  }

  // Add other methods for different operations (get, update, delete, etc.)
  getPosts(groupId: any,platforms:string[]=[]): Observable<any> {
    return this.http
      .get<any>(`${this.apiUrl}/posts/by-group/${groupId}?platforms=${platforms.join(',')}`, this.httpOptions)
      .pipe(
        catchError((error) => {
          console.error('Error fetching posts:', error);
          return throwError(() => error);
        })
      );
  }
  getDrafts(groupIds: number[],platforms:string[]=[]): Observable<any> {
    return this.http
      .get<any>(
        `${this.apiUrl}/posts/group/${groupIds}/drafts?platforms=${platforms.join(',')}`, this.httpOptions
)
      .pipe(
        catchError((error) => {
          console.error('Error fetching posts:', error);
          return throwError(() => error);
        })
      );
  }

  deleteDraft(draft: any): Observable<any> {
    return this.http.delete(`${this.apiUrl}/posts/${draft.id}`);
  }
  getYearPosts(year: number, groupIds: number[]): Observable<any> {
    return this.http
      .get<any>(
        `${this.apiUrl}/posts/count-by-date/${year}/${groupIds}`,
        this.httpOptions
      )
      .pipe(
        catchError((error) => {
          console.error('Error fetching Year posts:', error);
          return throwError(() => error);
        })
      );
  }

  getByDateRange(
    startDate: Date,
    endDate: Date,
    groupIds: number[],
    platforms:string[]=[]
  ): Observable<any> {
    // const startStr =
    //   startDate.getFullYear() +
    //   '-' +
    //   (startDate.getMonth() + 1) +
    //   '-' +
    //   startDate.getDate();
    // const endStr =
    //   endDate.getFullYear() +
    //   '-' +
    //   (endDate.getMonth() + 1) +
    //   '-' +
    //   endDate.getDate();
		const startStr = startDate.toISOString(); // ISO format includes date and time
  	const endStr = endDate.toISOString(); // ISO format includes date and time
  
		console.log("startStr", startStr);
		console.log("endStr", endStr);
    return this.http
      .get<any>(
        `${this.apiUrl}/posts/group/${groupIds}/get-posts-by-date-range/${startStr}/${endStr}?platforms=${platforms}`,
        this.httpOptions
      )
      .pipe(
        catchError((error) => {
          console.error('Error fetching posts:', error);
          return throwError(() => error);
        })
      );
  }

  getByTimeFrame(
    groupIds: number[],
    dateStr: string,
    timeframe: string
  ): Observable<any> {
    return this.http
      .get<any>(
        `${this.apiUrl}/posts/group/${groupIds}/timeframe?timeFrame=${timeframe}&date=${dateStr}`,
        this.httpOptions
      )
      .pipe(
        catchError((error) => {
          console.error('Error fetching groups:', error);
          return throwError(() => error);
        })
      );
  }
  // Add other methods for different operations (get, update, delete, etc.)
  getGroupPosts(id: number): Observable<any> {
    return this.http
      .get<any>(
        `${this.apiUrl}/posts/group/${id}?page=1&pageSize=10`,
        this.httpOptions
      )
      .pipe(
        catchError((error) => {
          console.error('Error fetching groups:', error);
          return throwError(() => error);
        })
      );
  }

  uploadFile(formData: any): Observable<UploadResponse> {
    Debug.log('formData before uploading img: ', formData);
    return this.http.post(`${this.apiUrl}/images/upload`, formData);
  }

  getGroupScopesFromLocalStorage(): any[] {
    let sessionDataStr = localStorage.getItem(environment.sessionData);
    if (sessionDataStr != undefined) {
      return JSON.parse(sessionDataStr).groupScopes;
    }
    return [];
  }

  saveGroupScopesToLocalStorage(data: any[]) {
    let sessionDataStr = localStorage.getItem(environment.sessionData);
    let sessionData: { groupScopes: any[] } = { groupScopes: [] };
    if (sessionDataStr) {
      sessionData = JSON.parse(sessionDataStr);
    }
    sessionData.groupScopes = data;
    localStorage.setItem(environment.sessionData, JSON.stringify(sessionData));
  }
}