import { Component } from '@angular/core';
import { GroupService } from 'src/app/services/repositories/group.service';
import { PostService } from 'src/app/services/repositories/post.service';
import { AuthService } from 'src/app/services/utilities/auth.service';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { UiPermissionsService } from 'src/app/services/utilities/ui-permissions.service';
import { Debug } from 'src/app/utils/debug';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent {
  constructor(
    private authService: AuthService,
    private uiPermissionService: UiPermissionsService,
    private postService: PostService,
    private groupService: GroupService,
    private translation: TranslationService
  ) {}
  authenticatedUser!: any;
  selectedGroups!: any;
  ngOnInit() {
    let tmpGroup: any = {};
    this.authService.loadCurrentUser().subscribe((res) => {
      // this.authService.currentUserSubject.next(res.data);
      let userAccreditations = JSON.parse(
        JSON.stringify(res.data.accreditations)
      );

      if (res && res.data) {
        this.authenticatedUser = res.data;
        //get first group of from user accreditations and set it to active group

        this.authenticatedUser.UI = {};
        if (this.authenticatedUser.isAdmin || userAccreditations.length == 1) {
          this.groupService.getGroups().subscribe((groups: any) => {
            if (groups && groups.data && groups.data.length > 0) {
              // Debug.log('user is Admin setting currentGroup to :',groups.data[0])
              this.authenticatedUser.accreditations = [];
              for (let group of groups.data) {
                this.authenticatedUser.accreditations.push({ group });
              }

              tmpGroup = groups.data[0];
              {
                if (this.postService.currentGroupSource.getValue().id == 0)
                  this.postService.updateGroup(tmpGroup);
              }
            }
          });
        } else {
          //if user has no accreditation we show message NO access to the application and log him out

          tmpGroup = this.authenticatedUser.accreditations[0].group;

          if (
            this.postService.currentGroupSource.getValue().id != tmpGroup.id
          ) {
            this.postService.updateGroup(tmpGroup);
          }
          Debug.log('non Admin User  accreditations', userAccreditations);
        }

        this.postService.currentGroups.subscribe((groupIds: number[]) => {
          this.selectedGroups = groupIds;
          this.uiPermissionService.getPermissions().subscribe((uiMaps) => {
            this.authenticatedUser.accreditations = userAccreditations;
            for (let uiMap of uiMaps) {
              if (uiMap.name === 'dashboard') {
                this.authenticatedUser.UI.canAccessDashboard =
                  this.uiPermissionService.isActionsOnUserAccreditations(
                    uiMap,
                    userAccreditations,
                    this.authenticatedUser.isAdmin
                  );
              }

              if (uiMap.name === 'post.creation') {
                this.authenticatedUser.UI.canAccessPostCreation =
                  this.uiPermissionService.isActionsOnUserAccreditations(
                    uiMap,
                    userAccreditations,
                    this.authenticatedUser.isAdmin
                  );
              }

              if (uiMap.name === 'post.library') {
                this.authenticatedUser.UI.canAccessPostLibrary =
                  this.uiPermissionService.isActionsOnUserAccreditations(
                    uiMap,
                    userAccreditations,
                    this.authenticatedUser.isAdmin
                  );
              }

              if (uiMap.name === 'calendar') {
                this.authenticatedUser.UI.canAccessCalendar =
                  this.uiPermissionService.isActionsOnUserAccreditations(
                    uiMap,
                    userAccreditations,
                    this.authenticatedUser.isAdmin
                  );
              }

              if (uiMap.name === 'sociabble') {
                this.authenticatedUser.UI.canAccessSociabble =
                  this.uiPermissionService.isActionsOnUserAccreditations(
                    uiMap,
                    userAccreditations,
                    this.authenticatedUser.isAdmin
                  );
              }

              if (uiMap.name === 'social.media.page.associations') {
                this.authenticatedUser.UI.canAccessSocialPages =
                  this.uiPermissionService.isActionsOnUserAccreditations(
                    uiMap,
                    userAccreditations,
                    this.authenticatedUser.isAdmin
                  );
              }

              if (uiMap.name === 'google.presence.managment') {
                this.authenticatedUser.UI.canAccessPresenceManagment =
                  this.uiPermissionService.isActionsOnUserAccreditations(
                    uiMap,
                    userAccreditations,
                    this.authenticatedUser.isAdmin
                  );
              }
              // add any other map (google presense management for example)
            }
            this.authService.updateAuthenticatedUser(this.authenticatedUser);
          });
        });
      }
    });
  }
}
