import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from 'src/app/components/base/base.component';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { Debug } from 'src/app/utils/debug';

@Component({
  selector: 'app-hours-form',
  templateUrl: './hours-form.component.html',
  styleUrls: ['./hours-form.component.scss'],
})
export class HoursFormComponent extends BaseComponent {
  @Input() regularHours: any;
  @Input() specialHours: any;
  @Input() moreHours: any;
  @Input() moreHoursTypes: any;
  @Input() openInfo: any;

  @Output() hoursChanged = new EventEmitter<any>();

  activeTab: string = 'regularHours';

  constructor(translationService: TranslationService) {
    super(translationService);
  }

  setActiveTab(tab: string) {
    this.activeTab = tab;
  }

  getTimeString(hoursObj: any): string {
    Debug.log('hoursObj', hoursObj);
    const hours = hoursObj.hours.toString().padStart(2, '0');
    const minutes = hoursObj.minutes
      ? hoursObj.minutes.toString().padStart(2, '0')
      : '00';
    return `${hours}:${minutes}`;
  }

  onRegularHoursChange(updatedRegularHours: any) {
    this.regularHours = updatedRegularHours;
    this.emitChanges();
  }

  onSpecialHoursChange(updatedSpecialHours: any) {
    this.specialHours = updatedSpecialHours;
    this.emitChanges();
  }

  onMoreHoursChange(updatedMoreHours: any) {
    this.moreHours = updatedMoreHours;
		console.log("onMoreHoursChange", this.moreHours )
    this.emitChanges();
  }

  onOpenInfoChange(updatedOpenInfo: any) {
    this.openInfo = updatedOpenInfo;
    this.emitChanges();
  }

  emitChanges() {
    const updatedHours = {
      regularHours: this.regularHours,
      specialHours: this.specialHours,
      moreHours: this.moreHours,
    };

    // Emit the updated hours to the parent component
    this.hoursChanged.emit(updatedHours);
  }

  getCurrentHours() {
    return {
      regularHours: this.regularHours,
      specialHours: this.specialHours,
      moreHours: this.moreHours,
      openInfo: this.openInfo,
    };
  }

  saveHours() {
    const updatedHours = this.getCurrentHours();

    // Emit the updated hours to the parent component
    Debug.log('Saving hours:', updatedHours);
  }
}
