import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/utilities/auth.service';
import Swal from 'sweetalert2';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { environment } from 'src/environments/environment';
import { Debug } from 'src/app/utils/debug';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  env = environment;
  constructor(
    private authService: AuthService,
    private router: Router,
    private translationService: TranslationService
  ) {}

  login(username: string, password: string, event: Event): void {
    event.preventDefault();

    this.authService.login(username, password).subscribe(
      () => {
        // Successful login, navigate to the posts
        // Swal.fire({
        //   icon: 'success',
        //   title: this.translationService.t('generic.connected'),
        //   showConfirmButton: false,
        //   timer: 1500,
        // });
        this.router.navigate(['/posts']);
      },
      (e) => {
        Debug.log('❌', e.error);
        Swal.fire({
          icon: 'error',
          titleText: this.translationService.t('generic.accessDenied'),
          text: this.translationService.t(e.error.message),
        });
        // Handle login error (e.g., display an error message)
        console.error('Login failed:', e.error);
      }
    );
  }
  switchEn() {
    this.translationService.setLanguage('en');
  }

  switchFr() {
    this.translationService.setLanguage('fr');
  }
}
