import { Component,Renderer2,ElementRef, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/utilities/auth.service';
import { Debug } from 'src/app/utils/debug';


@Component({
  selector: 'app-post-details',
  templateUrl: './post-details.component.html',
  styleUrls: ['./post-details.component.scss']
})
export class PostDetailsComponent {
  groupsDropDown: boolean = false;
  pagesDropDown: boolean = false;
  selectedPlateform: string = 'all';
  data: any = {};
  writeReply: any = null;
  pages: any[] = [];
  groups: any[] = [];
  comments: any[] = [{'comment':'bla bla bla bla bla bla blaa', 'name':'Taline LeBoulanger', 'date':'31/07/2023 – 12h31', 
                      'replies':[{'comment':'orem aucibus orci luctus et ultrices posuere cubilia', 'name':'Taline LeBoulanger', 'date':'31/07/2023 – 12h31'},  {'comment':'orem aucibus orci luctus et ultrices posuere cubilia', 'name':'Admin', 'date':'31/07/2023 – 12h31'}]},
                      {'comment':"e texte latin, généralement utilisé, proviendrait d'un texte latin écrit par Cicéron en 45 avant J.-C., sans copier le texte original au complet, il reprendrait seulement certaines parties et ajouterait des fautes. Ainsi, le mot lorem proviendrait du latin dolorem donnant la traduction de dolorem ipsum : la douleur.", 'name':'Oussama chabih', 'date':'31/07/2023 – 12h31', 
                        'replies':[{'comment':' mot lorem proviendrait du latin dolorem donnant la traductio', 'name':'Taline LeBoulanger', 'date':'31/07/2023 – 12h31'},  {'comment':"texte latin, généralement utilisé, proviendrait d'un texte latin écrit par Cicéron en 45 avant J.-C., sans copier le texte original au complet, il reprendrait seulement certaines parties et ajouterait des fautes. Ainsi, le mot lorem proviendrait du latin dolorem donnant ", 'name':'Admin', 'date':'31/07/2023 – 12h31'}]}];
  env = environment;
  textpost: string =  "Ce texte latin, généralement utilisé, proviendrait d'un texte latin écrit par Cicéron en 45 avant J.-C., sans copier le texte original au complet, il reprendrait seulement certaines parties et ajouterait des fautes. Ainsi, le mot lorem proviendrait du latin dolorem donnant la traduction de dolorem ipsum : la douleur."
  postUrl: any;
  postUrlAction: string = '';
  all: boolean = false;
  linkedin: boolean = false;
  google: boolean = false;
  facebook: boolean = false;
  instagram: boolean = false;
  linkedinPages: any[] = [];
  googlePages: any[] = [];
  facebookPages: any[] = [];
  instagramPages: any[] = [];
  linkedinData: any = null;
  googleData: any = null;
  facebookData: any = null;
  instagramData: any = null;
  allData: any = {'clickCount': 0, 'likeCount': 0, 'commentCount': 0, 'shareCount': 0, 'uniqueImpressionsCount': 0, 'engagement': 0};
  constructor(
    private eRef: ElementRef,
    private renderer: Renderer2,
    private authService: AuthService,
  ){}

  ngOnInit(){
    if (document.URL.split('?').length > 1) {
      const queryString = document.URL.split('?')[1];
      const params = queryString.split('&').map((item: any) => {
        return { key: item.split('=')[0], value: item.split('=')[1] };
      });

      for (let param of params) {
        //params will containe 'useAs' key and 'postTemplate' if it was redirected from library and 'postTemplate' if it was from calendar
        if (param.key === 'postTemplate') {
          this.data = JSON.parse(decodeURIComponent(param.value));
          console.log(this.data);
          let list: any[] = [];
          this.data.SocialPlatforms.forEach((item:  {url: any;externalId: string; source: string; group: any; pageName: any;})=>{
            if(item.source == "linkedin"){
              this.linkedinData = item;
              this.linkedinPages.push({'name':item.pageName, "url": "https://www.linkedin.com/feed/update/"+item.externalId, "source": item.source});
              this.pages.push({'name':item.pageName, "url": "https://www.linkedin.com/feed/update/"+item.externalId, "source": item.source});
            }
            if(item.source == "google"){
              this.googleData = item;
              this.googlePages.push({'name':item.pageName, "url": item.url, "source": item.source});
              this.pages.push({'name':item.pageName, "url": item.url, "source": item.source});
            }
            if(item.source == "facebook"){
              this.facebookData = item;
              this.facebookPages.push({'name':item.pageName, "url": "https://www.facebook.com/"+item.externalId, "source": item.source});
              this.pages.push({'name':item.pageName, "url": "https://www.facebook.com/"+item.externalId, "source": item.source});
            }
            if(item.source == "instagram"){
              this.instagramData = item;
              this.instagramPages.push({'name':item.pageName, "url": item.url, "source": item.source});
              this.pages.push({'name':item.pageName, "url": item.url, "source": item.source});
            }
            if(!list.includes(item.group.id)){
              this.groups.push({"name":item.group.name});
              list.push(item.group.id);
            }
          })
          console.log('facebook',this.facebookData);
        }
      }
      let numberOfSources = 0;
      this.data.SocialPlatforms.forEach((item: { source: string; })=>{
        if(item.source == "linkedin"){
          this.linkedin = true;
          numberOfSources++;
        }
        if(item.source == "google"){
          this.google = true;
          numberOfSources++;
        }
        if(item.source == "facebook"){
          this.facebook = true;
          numberOfSources++;
        }
        if(item.source == "instagram"){
          this.instagram = true;
          numberOfSources++;
        }
      })
      
      if(numberOfSources>1)
        this.all = true;
      else
        this.selectPlateform(this.data.SocialPlatforms[0].source)
      this.allStats();
    }
  }

  selectPlateform(plateform: string){
    switch (plateform){
      case 'linkedin':
        this.selectedPlateform = 'linkedin';
        break;
      case 'google':
        this.selectedPlateform = 'google';
        break;
      case 'facebook':
        this.selectedPlateform = 'facebook';
        break;
      case 'instagram':
        this.selectedPlateform = 'instagram';
         break;
      default:
        this.selectedPlateform = 'all';
    }

  }

  dropdownpages(){
    this.pagesDropDown = !this.pagesDropDown;
      if (this.pagesDropDown) {
        this.renderer.listen('document', 'click', (event) => {
          if (!this.eRef.nativeElement.contains(event.target)) {
            this.pagesDropDown = false;
          }
        });
      }
  }

  dropDownOpen(type:string){
    if(type == "pages"){
      this.pagesDropDown = !this.pagesDropDown;
      this.groupsDropDown = false;
      if (this.pagesDropDown) {
        this.renderer.listen('document', 'click', (event) => {
          if (!this.eRef.nativeElement.contains(event.target)) {
            this.pagesDropDown = false;
          }
        });
      }
      return
    }
    if(type == "groups"){
      this.groupsDropDown = !this.groupsDropDown;
      this.pagesDropDown = false;
    }
  }

  reply(value: any) {
    this.writeReply = value;
  }
  
  allStats(){
    this.allData.clickCount = this.gVal(this.linkedinData?.postAnalytics?.clickCount) + this.gVal(this.facebookData?.postAnalytics?.clickCount)
                              + this.gVal(this.googleData?.postAnalytics?.clickCount) + this.gVal(this.instagramData?.postAnalytics?.clickCount)
    this.allData.likeCount = this.gVal(this.linkedinData?.postAnalytics?.likeCount) + this.gVal(this.facebookData?.postAnalytics?.likeCount)
                              + this.gVal(this.googleData?.postAnalytics?.likeCount) + this.gVal(this.instagramData?.postAnalytics?.likeCount)
    this.allData.commentCount = this.gVal(this.linkedinData?.postAnalytics?.commentCount) + this.gVal(this.facebookData?.postAnalytics?.commentCount)
                              + this.gVal(this.googleData?.postAnalytics?.commentCount) + this.gVal(this.instagramData?.postAnalytics?.commentCount)
    this.allData.shareCount = this.gVal(this.linkedinData?.postAnalytics?.shareCount) + this.gVal(this.facebookData?.postAnalytics?.shareCount)
                              + this.gVal(this.googleData?.postAnalytics?.shareCount) + this.gVal(this.instagramData?.postAnalytics?.shareCount)
    this.allData.uniqueImpressionsCount = this.gVal(this.linkedinData?.postAnalytics?.uniqueImpressionsCount) + this.gVal(this.facebookData?.postAnalytics?.uniqueImpressionsCount)
                              + this.gVal(this.googleData?.postAnalytics?.uniqueImpressionsCount) + this.gVal(this.instagramData?.postAnalytics?.uniqueImpressionsCount)
    this.allData.engagement = this.gVal(this.linkedinData?.postAnalytics?.engagement) + this.gVal(this.facebookData?.postAnalytics?.engagement)
                              + this.gVal(this.googleData?.postAnalytics?.engagement) + this.gVal(this.instagramData?.postAnalytics?.engagement)
  }

  gVal(data: any){
    return data && Number(data)? Number(data): 0
  }

  renderVal(data: any){
    return data!=undefined ? data: '-'
  }

}