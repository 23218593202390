import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseComponent } from '../../base/base.component';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { PostService } from 'src/app/services/repositories/post.service';
import { UiStateService } from 'src/app/services/utilities/ui-state.service';
import { Debug } from 'src/app/utils/debug';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/services/utilities/auth.service';

@Component({
  selector: 'app-post-preview',
  templateUrl: './post-preview.component.html',
  styleUrls: ['./post-preview.component.scss'],
})
export class PostPreviewComponent extends BaseComponent {
  constructor(
    translationService: TranslationService,
    protected postService: PostService,
    protected uiStateService: UiStateService,
    private http: HttpClient,
    protected authService: AuthService
  ) {
    super(translationService);
  }

  ngAfterViewInit() {
    this.attachImageLoadListeners();
    this.observeContentChanges();
    this.adjustOverlayHeight();
  }

  private mutationObserver!: MutationObserver;
  mainMedia: any = null;

  ngOnInit(): void {
    if (this.post.title == undefined || this.post.title == '') {
      this.post.title = 'N/A';
    }
    this.uiStateService.platformsFilterUpdated$.subscribe((data) => {
      // Debug.log(data)
      this.currentPlaTformsFilters = data;
      this.isPostPlatformSelected();
    });
    if(this.displayAs == 'planned'){
      console.log(this.post);
      
    }

    this.mutationObserver = new MutationObserver(() => {
      this.adjustOverlayHeight();
    });

		this.setMainMedia();
    this.postAnalytics();
    
    
  }
	

  @Output() onDeleteClicked: EventEmitter<any> = new EventEmitter();
  emitOpenModal() {
    this.onDeleteClicked.emit();
  }

  @ViewChild('overlay') overlayElement!: ElementRef;
  @ViewChild('content') contentElement!: ElementRef;
  private adjustOverlayHeight(): void {
    const contentHeight = this.contentElement.nativeElement.offsetHeight;
    this.overlayElement.nativeElement.style.height = `${contentHeight}px`;
  }

  private attachImageLoadListeners(): void {
    const images: NodeListOf<HTMLImageElement> =
      this.contentElement.nativeElement.querySelectorAll('img');
    images.forEach((img) => {
      if (!img.complete) {
        img.onload = () => this.adjustOverlayHeight();
      }
    });
  }

  private observeContentChanges(): void {
    this.mutationObserver.observe(this.contentElement.nativeElement, {
      childList: true,
      subtree: true,
      attributes: false,
      characterData: false,
    });
  }

  currentPlaTformsFilters: any = {
    isFacebookON: true,
    isLinkedinON: true,
    isGoogleON: true,
    isInstagramON: true,
  };

  isPublished: boolean = true;
  isPlanned: boolean = true;

  getPostGroupsNamesList() {
    let groupListStr: string = '';
    for (let socialMedia of this.post.SocialPlatforms) {
      if (!groupListStr.includes(socialMedia.group.name)) {
        groupListStr += socialMedia.group.name + ' ' + ',';
      }
    }

    return groupListStr.substring(0, groupListStr.length - 1).trim();
  }

  
  setMainMedia(): void {
    if (this.post.SocialPlatforms?.length > 0) {
      for (const socialMedia of this.post.SocialPlatforms) {
        if (socialMedia.medias?.length > 0) {
          this.mainMedia = socialMedia.medias[0];
          break;
        }
      }
    }
	}

	mediaIsVideo(media: any): boolean {
    return media?.mimeType?.startsWith('video') ?? false;
  }

  formatPostStats(num: number) {
    if (Math.abs(num) < 1000) {
      return num.toString();
    } else if (Math.abs(num) >= 1000 && Math.abs(num) < 1000000) {
      return (Math.sign(num) * (Math.abs(num) / 1000)).toFixed(1) + 'k';
    } else {
      return (Math.sign(num) * (Math.abs(num) / 1000000)).toFixed(1) + 'm';
    }
  }

  @Input() post: any;
  @Input() postIndex!: number;
  @Input() displayAs!: string;
  @Input() filterValue!: boolean;
  apiUrl = environment.apiUrl;
  @Input() user: any;

  useAsNewPostTemplate() {
      const postJsonStr = JSON.stringify(this.post);
      const mediaQuery = `/posts?useAs=${this.displayAs}&postTemplate=${encodeURIComponent(postJsonStr)}`;
        
			Debug.log(mediaQuery);
			window.location.href = mediaQuery;
		
  }

  calculatePastTimeFromNow() {
    let postTime = '';
    let futurePost = false;
    if (this.displayAs === 'post' || this.displayAs === 'planned' ) {
      postTime =
        this.post.publishingDatetime == null
          ? this.post.expectedPublishingDatetime
          : this.post.publishingDatetime;
    }
    if (this.displayAs === 'draft') {
      postTime = this.post.createdAt;
    }
    const postTimeUtc = new Date(postTime);

    const now = new Date(); // Current time

    let delta = Math.abs(now.getTime() - postTimeUtc.getTime()) / 1000; // Delta in seconds

    if (postTimeUtc > now) {
      // Post is in the future
      futurePost = true;
    }

    // Calculate differences and round up if necessary
    let unit = '';
    let value = 0;

    if (delta >= 3600 * 24 * 365) {
      value = Math.ceil(delta / (3600 * 24 * 365));
      unit = 'year';
    } else if (delta >= 3600 * 24 * 30) {
      value = Math.ceil(delta / (3600 * 24 * 30));
      unit = 'month';
    } else if (delta >= 3600 * 24) {
      value = Math.ceil(delta / (3600 * 24));
      unit = 'day';
    } else if (delta >= 3600) {
      value = Math.ceil(delta / 3600);
      unit = 'hour';
    } else if (delta >= 60) {
      value = Math.ceil(delta / 60);
      unit = 'minute';
    } else {
      value = Math.ceil(delta);
      unit = 'second';
    }

    // Format output
    let output = `${this.getTranslation(
      futurePost ? 'generic.willBePostedIn' : 'generic.hasbeen'
    )} ${value} ${this.getTranslation(
      `generic.short-${unit}${value > 1 ? 's' : ''}`
    )} `;
    if (this.displayAs == 'post')
      output =
       // this.getTranslation('generic.published') +
        // ' ' +
        output +
        ' ' +
        this.getTranslation('generic.on');
    if (this.displayAs == 'draft')
      output = this.getTranslation('generic.createdDraft') + ' ' + output;
    return output.trim();
  }

  private _canShowPreview: boolean = true;

  // Use a setter for the canShowPreview property
  set canShowPreview(value: boolean) {
    this._canShowPreview = value;
    if (this.contentElement) {
      const contentHeight = this.contentElement.nativeElement.offsetHeight;

      const overlayHeight = this.overlayElement.nativeElement.offsetHeight;
      if (!value)
        Debug.log(
          '_canShowPreview is now false for post',
          this.postIndex,
          'contentHeight',
          contentHeight,
          'overLayHeight',
          overlayHeight
        );
      else
        Debug.log(
          '_canShowPreview is now true for post',
          this.postIndex,
          'contentHeight',
          contentHeight,
          'overLayHeight',
          overlayHeight
        );
    }
    // Check if the view is initialized to avoid errors
    if (value && this.contentElement) {
      // Adjust the overlay height when the value changes to true
      setTimeout(() => this.adjustOverlayHeight(), 300);
    }
  }

  // Use a getter to maintain accessibility to the canShowPreview value
  get canShowPreview(): boolean {
    return this._canShowPreview;
  }

  isPostPlatformSelected(): boolean {
    let showfacebook =
      this.post.SocialPlatforms.filter((sm: any) => {
        sm.source == 'facebook' && this.currentPlaTformsFilters.isFacebookON;
      }).length > 0;
    let showlinkedin =
      this.post.SocialPlatforms.filter((sm: any) => {
        sm.source == 'linkedin' && this.currentPlaTformsFilters.isLinkedinON;
      }).length > 0;
    let showgoogle =
      this.post.SocialPlatforms.filter((sm: any) => {
        sm.source == 'google' && this.currentPlaTformsFilters.isGoogleON;
      }).length > 0;
    let showInstagram =
      this.post.SocialPlatforms.filter((sm: any) => {
        sm.source == 'instagram' && this.currentPlaTformsFilters.isInstagramON;
      }).length > 0;
    this.canShowPreview = this.areAllPostPlatformsFiltred();

    return showfacebook || showgoogle || showlinkedin || showInstagram;
  }

  areAllPostPlatformsFiltred(): boolean {
    let allSelected: boolean[] = [];
    let currentPostPlatforms = this.post.SocialPlatforms;
    currentPostPlatforms.forEach((platform: any) => {
      switch (platform.source) {
        case 'facebook':
          allSelected.push(this.currentPlaTformsFilters.isFacebookON);
          break;
        case 'google':
          allSelected.push(this.currentPlaTformsFilters.isGoogleON);
          break;
        case 'linkedin':
          allSelected.push(this.currentPlaTformsFilters.isLinkedinON);
          break;
        case 'instagram':
          allSelected.push(this.currentPlaTformsFilters.isInstagramON);
          break;
      }
    });

    const allFalse = allSelected.every((value) => value === false);
    return !allFalse;
  }

  ngOnDestroy(): void {
    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }
  }

  postDetails(){
    const postJsonStr = JSON.stringify(this.post);

    const mediaQuery = `/library/post-details?useAs=&postTemplate=${encodeURIComponent(postJsonStr)}`;
    window.location.href = mediaQuery;
  }

  postAnalytics(){
    this.post.views = 0;
    this.post.clicks = 0;
    this.post.comments = 0;
    this.post.shares = 0;
    this.post.SocialPlatforms.forEach((item: any)=>{
      if(item.source == "linkedin"){
        this.post.views += this.gVal(item?.postAnalytics?.likeCount);
        this.post.clicks += this.gVal(item?.postAnalytics?.clickCount);
        this.post.comments += this.gVal(item?.postAnalytics?.commentCount);
        this.post.shares += this.gVal(item?.postAnalytics?.shareCount);
      }
    })
  }

  gVal(data: any){
    return data && Number(data)? Number(data): 0
  }
}
