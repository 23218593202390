<div class="container mx-auto px-4">
	<!-- Standard menu -->
	<div class="flex justify-between mb-5"
		[ngClass]="{'hidden': multiSelectionMode}"
	>
		<span>{{'pages.yourGooglePages'|translate}}</span>
		<a routerLink="/presence-management/create-page" class="hidden text-sm hover:svg-fill-cliking_blue flex justify-center items-center shadow-md rounded-lg text-white bg-cliking_blue hover:bg-white hover:text-cliking_blue py-2 px-6 border border-cliking_blue" >
			<svg class="icon" width="24" height="24" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M23.6098 2.91675H11.3889C6.0806 2.91675 2.91602 6.08133 2.91602 11.3897V23.5959C2.91602 28.9188 6.0806 32.0834 11.3889 32.0834H23.5952C28.9035 32.0834 32.0681 28.9188 32.0681 23.6105V11.3897C32.0827 6.08133 28.9181 2.91675 23.6098 2.91675ZM26.2493 18.5938H18.5931V26.2501C18.5931 26.848 18.0973 27.3438 17.4993 27.3438C16.9014 27.3438 16.4056 26.848 16.4056 26.2501V18.5938H8.74935C8.15143 18.5938 7.6556 18.098 7.6556 17.5001C7.6556 16.9022 8.15143 16.4063 8.74935 16.4063H16.4056V8.75008C16.4056 8.15216 16.9014 7.65633 17.4993 7.65633C18.0973 7.65633 18.5931 8.15216 18.5931 8.75008V16.4063H26.2493C26.8473 16.4063 27.3431 16.9022 27.3431 17.5001C27.3431 18.098 26.8473 18.5938 26.2493 18.5938Z" 
					fill="currentColor"/>
			</svg>
			<span class="ml-1">{{'pages.createNewGooglePage'|translate}}</span>
		</a>	
	</div>
	<!-- Multiselection menu -->
	<div class="flex justify-between mb-5"
		[ngClass]="{'hidden': !multiSelectionMode}"
	>
		<span>{{selectedPageIds.length}} {{'pages.selectedPages'|translate}}</span>
		<div class="flex gap-4">
			<div class="text-sm hover:svg-fill-cliking_blue flex justify-center items-center shadow-md rounded-lg text-white bg-cliking_red hover:bg-white hover:text-cliking_red py-2 px-6 border border-cliking_red hover:cursor-pointer" 
				(click)="cancelSelection()"
			>
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
					<path stroke-linecap="round" stroke-linejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
				</svg>
				
				<span class="ml-1">{{'pages.cancelSelection'|translate}}</span>
			</div>
			<div class="text-sm hover:svg-fill-cliking_blue flex justify-center items-center shadow-md rounded-lg text-white bg-cliking_blue hover:bg-white hover:text-cliking_blue py-2 px-6 border border-cliking_blue hover:cursor-pointer" 
				(click)="selectAllPages()"
			>
			<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
				<path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
			</svg>
							
				<span class="ml-1">{{'pages.selectAll'|translate}}</span>
			</div>
			<a class="text-sm hover:svg-fill-cliking_green flex justify-center items-center shadow-md rounded-lg text-white bg-cliking_green hover:bg-white hover:text-cliking_green py-2 px-6 border border-cliking_green" 
				[routerLink]="['/presence-management/edit-page']"
				[queryParams]="{ pageIds: selectedPageIds.join(',') }" >
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
					<path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
				</svg>
				
				<span class="ml-1">{{'pages.editSelection'|translate}}</span>
			</a>
		</div>
	</div>
	<div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 gap-4">
		<!-- Page cards -->
		<div *ngFor="let page of pages; let i = index"
			(mouseover)="displayCardOption(i, true)"
			(mouseout)="displayCardOption(i, false)"
			(click)="handleCardClick(page.socialMedia.id, $event)"
			class="w-60 h-64 border border-gray-400 rounded-2xl overflow-hidden relative hover:cursor-pointer"
			[ngClass]="{'border-[#1DC9B7]': isPageSelected(page.socialMedia.id)}"
		>
			<div class="absolute top-0 right-0 z-10" 
				[ngClass]="{'hidden': true || (!hoverState[i] && !multiSelectionMode)}"
				(click)="handleSelectPage(page.socialMedia.id, $event)"
			> <!-- Check icon -->
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" [attr.stroke]="isPageSelected(page.socialMedia.id) ? 'white' : 'currentColor'" class="size-10">
					<circle cx="12" cy="12" r="9" [attr.fill]="isPageSelected(page.socialMedia.id) ? '#1DC9B7' : 'white'"/>
					<path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
				</svg>
				
			</div>
			<div class="h-1/2 ">
				<img [src]="page.googleData.medias[0] || 'assets/img/nomedia1.png'" alt="logo" class="h-full w-full flex-grow object-cover">
			</div>
			<div class="h-1/2 p-4 border-t border-gray-400 flex flex-col justify-between bg-white">
				<span class="font-medium text-lg">{{page.googleData.title}}</span> <!-- Title -->
				<span class="font-light text-xs truncate whitespace-nowrap overflow-hidden block w-48">{{page.googleData.categories.primaryCategory.displayName}}</span>

			</div>
			
		</div>
		<!-- <app-google-page-preview 
			[medias]="page ? page.medias : []"
			[title]="page.googleData.title"
			[primaryCategory]="page.googleData.categories.primaryCategory.displayName"
			[address]="''"
			[phoneNumber]="page.googleData.phoneNumbers.primaryPhone"
			[description]="page.googleData.profile.description"
			[horaires]="page ? {regularHours: page.googleData.regularHours, specialHours: page.googleData.specialHours} : {}"
		>
		</app-google-page-preview> -->
	</div>
</div>