import {
  Component,
  HostListener,
  QueryList,
  ElementRef,
  ViewChildren,
  ViewChild,
  Input,
} from '@angular/core';

import { PostService } from 'src/app/services/repositories/post.service';
import { UiStateService } from 'src/app/services/utilities/ui-state.service';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { CalenderBaseComponent } from 'src/app/components/base/calendar.component';
import { CalendarService } from 'src/app/services/utilities/calendar.service';
import { CALENDERMODE } from 'src/app/interfaces/CalendarMode';
import { Debug } from 'src/app/utils/debug';

@Component({
  selector: 'app-calendar-week-view',
  templateUrl: './calendar-week-view.component.html',
  styleUrls: ['./calendar-week-view.component.scss'],
})
export class CalendarWeekViewComponent extends CalenderBaseComponent {
  ngAfterViewInit() {
    this.scrollToElement();
  }

  scrollToElement() {
    Debug.log('scroling to post-label ...');
    let elementId = 'post-label';
    setTimeout(() => {
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 1000);
  }

  constructor(
    protected override uiStateService: UiStateService,
    protected override calendarService: CalendarService,
    protected override postService: PostService,
    protected override translationService: TranslationService
  ) {
    super(translationService, calendarService, postService, uiStateService);
  }
  currentWeekStart: Date = new Date();
  currentWeekEnd: Date = new Date();
  startMonthStr: string = '';
  endMonthStr: string = '';
  @Input() screenWidth: number = 0
  @Input() screenHeight: number = 0

  override ngOnInit() {
    super.ngOnInit();
    this.postService.groupsUpdeted$.subscribe((groups) => {
      this.groupScopes = groups;
      Debug.log('selected groups updated', this.groupScopes);
      if (this.groupScopes.length > 0) {
        this.getWeekPosts();
        this.scrollToElement();
      }
    });

    // this.groupSubscription = this.postService.groupUpdeted$.subscribe(group => {
    //   if(group.id!=0){

    //     this.getWeekPosts()
    //     this.scrollToElement()
    //    }
    // })

    this.updateDates();
    this.getWeekPosts();
    this.scrollToElement();
    // add basge with the number of pousts on each week day if any
  }

  generateRange(n: number): number[] {
    return Array.from({ length: n }, (v, k) => k);
  }

  updateDates() {
    let dates = this.getStartAndEndOfWeek(this.currentDate);
    this.currentWeekStart = dates.startOfWeek;
    this.currentWeekEnd = dates.endOfWeek;
    this.startMonthStr = this.getTranslation(
      `generic.month${this.currentWeekStart.getMonth() + 1}`
    );
    this.endMonthStr = this.getTranslation(
      `generic.month${this.currentWeekEnd.getMonth() + 1}`
    );
  }

  prevWeek() {
    this.navigateWeek(-7);
    this.scrollToElement();
  }

  nextWeek() {
    this.navigateWeek(7);
    this.scrollToElement();
  }

  getPostHour(post: any): number {
    let dateStr = post.publishingDatetime
      ? post.publishingDatetime
      : post.expectedPublishingDatetime;
    return new Date(dateStr).getHours();
  }

  getPostTime(post: any): string {
    let dateStr = post.publishingDatetime
      ? post.publishingDatetime
      : post.expectedPublishingDatetime;
    return (
      new Date(dateStr).getHours() +
      ':' +
      new Date(dateStr).getMinutes() +
      ':' +
      new Date(dateStr).getSeconds()
    );
  }

  getNextDay(date: Date, addDays: number): Date {
    let nextDay = new Date(date); // Create a new Date instance to avoid mutating the original date
    nextDay.setDate(date.getDate() + addDays);
    return nextDay;
  }

  preparePostForLabelPreviewComponent(posts: any, hour: number) {
    if (posts == undefined || !posts.hasOwnProperty(hour)) return;
    // Debug.log("before merge items @",hour,posts[hour],"count",posts[hour].length,weekDayStr)

    const mergedPosts: { [key: string]: any } = {};
    if (posts && Object.keys(posts).includes(hour.toString())) {
      const Posts = posts[hour];
      // Debug.log(Posts)
      Posts.forEach((post: any) => {
        // Create a unique key based on all properties except 'SocialPlatform'
        const key = this.createUniqueKey(post, ['SocialPlatform', 'id']);
        if (!mergedPosts[key]) {
          const { id, SocialPlatform, ...restOfPost } = post;
          mergedPosts[key] = { ...restOfPost, SocialPlatform: [] };
        }
        // Add the 'SocialPlatform' to the array in the merged post
        mergedPosts[key].SocialPlatform.push({
          name: post.SocialPlatform,
          postId: post.id,
        });
      });
    }
    // Debug.log("after merge",Object.values(mergedPosts))
    return Object.values(mergedPosts);
  }

  createUniqueKey(post: any, excludeKeys: string[]): string {
    return Object.entries(post)
      .filter(([key]) => !excludeKeys.includes(key))
      .map(([key, value]) => `${key}:${value}`)
      .join('|');
  }

  navigateWeek(value: number) {
    this.currentDate.setDate(this.currentDate.getDate() + value);
    this.updateDates();
    this.getWeekPosts();
  }

  getWeekPosts() {
    const lsData = this.postService.getGroupScopesFromLocalStorage();
    this.groupScopes = lsData.length > 0 ? lsData : this.groupScopes;
    const { startOfWeek, endOfWeek } = this.getStartAndEndOfWeek(
      this.currentDate
    );
    if (this.groupScopes.length > 0) {
      let m = this.calendarService.currentDate.getMonth() + 1;
      let dateStr: string = `${this.calendarService.currentDate.getFullYear()}-${m
        .toString()
        .padStart(2, '0')}-${this.calendarService.currentDate.getDate()}`;
      Debug.log('⚡⚡', 'Start', startOfWeek, 'End', endOfWeek);
      this.postService
        .getByTimeFrame(this.groupScopes, dateStr, 'week')
        .subscribe((res) => {
          this.weekPosts = this.getObjectsInRange(
            res.data,
            startOfWeek,
            endOfWeek
          );
        });
    }
  }
  /*
  for week days header 
  */
  emValue: number = 7.72;
  dynamicWidthStyle(): string {
    return `calc(100% - ${this.emValue}em)`;
  }
  @HostListener('window:resize', ['$event'])
  onResize() {
    // Example condition to adjust emValue based on window width
    const width = window.innerWidth;

    if (width < 640) {
      // Tailwind's 'sm' breakpoint
      this.emValue = 7.72;
    } else if (width < 768) {
      // 'md'
      this.emValue = 7.72;
    } else if (width < 1024) {
      // 'lg'
      this.emValue = 7.72;
    } else {
      // 'xl' and up
      this.emValue = 7.76;
    }
  }

  onWeekPostsLoaded() {
    // Handle any operations needed after weekPosts is loaded, e.g., update the UI
    Debug.log('Week posts loaded:', this.weekPosts);
  }
  getWeekDay(index: number): string {
    if (!this.weekPosts) {
      return ''; // Return a default or empty string if weekPosts is not ready
    }
    return Object.keys(this.weekPosts)[index]
      ? Object.keys(this.weekPosts)[index].toString()
      : '';
  }

  private getObjectsInRange(
    objects: Array<{
      publishingDatetime?: Date;
      expectedPublishingDatetime?: Date;
    }>,
    startDate: Date,
    endDate: Date
  ): { [key: string]: { [hour: number]: any[] } } {
    const addDays = (date: Date, days: number): Date => {
      const result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    };

    const formatDate = (date: Date): string => {
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // getMonth() is zero-indexed
      const day = date.getDate();
      return `${year}-${month.toString().padStart(2, '0')}-${day
        .toString()
        .padStart(2, '0')}`;
    };

    let result: { [key: string]: { [hour: number]: any[] } } = {};

    let currentDate = new Date(startDate.toDateString()); // Reset hours to avoid DST issues
    while (currentDate <= endDate) {
      const formattedDate = formatDate(currentDate);
      result[formattedDate] = {};
      currentDate = addDays(currentDate, 1);
    }

    objects.forEach((obj) => {
      const objDate = obj.publishingDatetime || obj.expectedPublishingDatetime;
      if (objDate) {
        const date = new Date(objDate);
        const formattedObjDate = formatDate(date);
        const hour = date.getHours();
        if (!result[formattedObjDate]) {
          result[formattedObjDate] = {};
        }
        if (!result[formattedObjDate][hour]) {
          result[formattedObjDate][hour] = [];
        }

        result[formattedObjDate][hour].push(obj);
      }
    });
    return result;
  }

  dateToString(date: Date): string {
    return (
      date.getFullYear() +
      '-' +
      (date.getMonth() + 1).toString().padStart(2, '0') +
      '-' +
      date.getDate().toString().padStart(2, '0')
    );
  }

  getStartAndEndOfWeek(date: Date) {
    // Create a new Date object to avoid modifying the original date
    let start = new Date(date);
    let end = new Date(date);

    // Adjusting the start date to the Monday of the current week
    // If today is Sunday (0), set to the previous Monday (-6). Otherwise, set to the previous Monday (1 - current day).
    start.setDate(
      start.getDate() - (start.getDay() === 0 ? 6 : start.getDay() - 1)
    );

    // Adjusting the end date to the Sunday of the current week
    // If today is Sunday (0), no need to add days. Otherwise, add days to reach the next Sunday (7 - current day).
    end.setDate(end.getDate() + (end.getDay() === 0 ? 0 : 7 - end.getDay()));

    // Reset the time part to the start and end of the day
    start.setHours(0, 0, 0, 0);
    end.setHours(23, 59, 59, 999);

    return { startOfWeek: start, endOfWeek: end };
  }

  switchToMonthView(month: number): void {
    // Debug.log("switching to month",month)
    this.calendarService.setCurrentMonth(month);
    this.calendarService.setCurrentCalendarMode(CALENDERMODE.MONTH);
  }
  switchToYearView(year: number): void {
    // Debug.log("switching to year",year)
    this.calendarService.setCurrentYear(year);
    this.calendarService.setCurrentCalendarMode(CALENDERMODE.YEAR);
  }
  switchToDay(day: number) {
    const newDate = new Date(this.currentWeekStart);
    newDate.setDate(this.currentWeekStart.getDate() + day);
    this.calendarService.setCurrentDate(newDate);
    this.calendarService.setCurrentCalendarMode(CALENDERMODE.DAY);
  }
}
