  <!-- Group Dropdown -->
  <div class="z-50 absolute top-0 left-1/2 transform -translate-x-1/2 ml-[9%] w-[57%] mr-64 grid grid-rows-1 grid-cols-12 gap-0 h-[6.78%] c-bg-blue rounded-b-3xl">
    <div class=" mb-4 ml-5 mr-5 col-span-5 mt-0">
      <div class="relative  w-[100%] top-[13%] text-white" [ngClass]="{'text-gray-600':groupsFlatList.length==0, 'cursor-pointer':groupsFlatList.length>0 }">
        <div (click)="toggleDropdown()" type="button" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label" class="z-40 flex items-center">
          <svg [attr.width]="screenWidth * 1.95/100" [attr.height]="screenHeight * 5.1/100" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <path d="M4 10.1433C4 5.64588 7.58172 2 12 2C16.4183 2 20 5.64588 20 10.1433C20 14.6055 17.4467 19.8124 13.4629 21.6744C12.5343 22.1085 11.4657 22.1085 10.5371 21.6744C6.55332 19.8124 4 14.6055 4 10.1433Z" stroke="currentColor" stroke-width="1.5"></path>
          <circle cx="12" cy="10" r="3" stroke="#ffffff" stroke-width="1.5"></circle>
        </g>
          </svg>
          <span *ngIf="screenHeight>1025" class="block w-96 px-3 py-2 rounded-md focus:outline-none focus:ring-blue-500 focus:c-border-blue c-bg-blue text-white truncate max-w-full" [ngStyle]="{'font-size': screenHeight/150 + screenWidth/150 + 'px'}" >{{ updateCaption() }}</span>
          <span *ngIf="screenHeight<1025" class="block w-96 px-3 py-2 rounded-md focus:outline-none focus:ring-blue-500 focus:c-border-blue c-bg-blue text-white truncate max-w-full" [ngStyle]="{'font-size': screenHeight/140 + screenWidth/140 + 'px'}" >{{ updateCaption() }}</span>
          <span class="ml-auto flex items-center">
            <svg class="mr-2 focus:outline-none focus:ring-blue-500 focus:c-border-blue c-bg-blue text-white" [attr.width]="screenWidth * 1/100" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 520 512">
              <path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
            </svg>
          </span>
        </div>
        <ul *ngIf="isDropdownOpen" class="absolute w-full mt-[14px] -ml-10 pb-3 z-10 bg-white rounded-br-2xl rounded-bl-2xl border border-1 border-cliking_blue"  style="left: 4.9vh">
          <span>
            <li class="font-normal ml-3 cursor-pointer text-sm  w-full">
              <div>
                <div class="mt-1 flex ">
                  <div class="relative flex items-stretch flex-grow focus-within:z-10">
                    <input   type="search" class="px-4 py-10" (keyup)="searchItems(searchPattern)" [(ngModel)]="searchPattern" name="search" id="search" class="focus:ring-indigo-500 focus:border-indigo-500 block w-[94%] h-[3vh]  mt-2 pl-2 rounded-md sm:text-sm border border-1 border-gray-300 text-black" />
                  </div>
                </div>
              </div>
            </li>
          </span>
          <li  (click)="toggleSelectAll()" class="cursor-pointer text-sm select-none h-10 relative py-1 pl-3 pr-9 hover:bg-gray-100 flex items-center">
            <span class="text-black mt-1">{{'generic.selectAll' | translate}}</span>
            <input type="checkbox" [checked]="isAllSelected()" id="selectAll" class="absolute inset-y-3 right-2 h-3 w-3 mr-3" />
          </li>
          <diV *ngFor="let item of groupTree">
            <li *ngIf="(item.isVisible)" class="cursor-pointer text-sm select-none relative py-1 pl-3 pr-9 hover:bg-gray-100 item-center">
              <div  (click)="toggleOpen(item, $event)" class="flex items-center" [ngStyle]="{'margin-left.px': item.level}">
                <svg *ngIf="item.children.length == 0" width="23px" height="29px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                  <g id="SVGRepo_iconCarrier">
                    <path d="M4 10.1433C4 5.64588 7.58172 2 12 2C16.4183 2 20 5.64588 20 10.1433C20 14.6055 17.4467 19.8124 13.4629 21.6744C12.5343 22.1085 11.4657 22.1085 10.5371 21.6744C6.55332 19.8124 4 14.6055 4 10.1433Z" stroke="#5C78FF" stroke-width="1.5"></path>
                    <circle cx="12" cy="10" r="3" stroke="#5C78FF" stroke-width="1.5"></circle>
                  </g>
                </svg>
                <span *ngIf="item.children.length > 0 " class=" text-cliking_blue text-xl h-8 pt-[3px] ml-2 mr-[5px] rounded-full">|</span>
                <span *ngIf="searchPattern==''" class="font-normal hover:text-cliking_blue mr-3 text-gray-700 font-sans  font-black block truncate rounded-sm">
                  {{item.name }}
                </span>
                <span *ngIf="searchPattern!=''" class="font-normal hover:text-cliking_blue mr-3 text-gray-700 font-sans font-black block truncate rounded-sm" [innerHTML]="item.highlightedName"></span>
              </div>
              <input type="checkbox" (change)="toggleSelected(item, $event)" [checked]="groupScopes.includes(item.id)" class="form-checkbox absolute inset-y-3 right-2 flex items-center h-3 w-3 mr-3" /> 
              
            </li>
          </diV>
          <li class="flex justify-end pr-3 mt-2">
            <button (click)="setAsCurrentGroup(groupScopes,$event)" [disabled]="groupScopes.length == 0" [ngClass]="{'cursor-not-allowed':groupScopes.length == 0}" class="w-11/12 h-8 flex mr-2 items-center justify-center px-4 py-2 bg-cliking_blue text-lg text-white rounded-md hover:bg-blue-700">
              {{'generic.submit' | translate}}
            </button>  
          </li>
        </ul>
      </div>
    </div>
    <div class="col-span-2">
      <img class="absolute border-4 c-border-blue top-1 rounded-full" [style.width.px]="screenWidth * 6.25/100" src="{{apiUrl}}/assets/mma-assurance-icone.png" alt="">
    </div>
    <div class="col-span-4  flex justify-between">
      <span class="flex ml-auto -mr-[18%]">
        <!--Language -->
        <app-language [isMobileView]="isMobileView" [windowWidth]="windowWidth" />
        <!--Settings -->
        <app-inner-settings [uiMaps]="uiMaps" [auth]="auth" [accreditedGroups]="accreditedGroups" [isMobileView]="isMobileView" [windowWidth]="windowWidth" />
      </span>
    </div>
  </div>
  