import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SocialMediaAPIService {
  
  constructor(private http:HttpClient) { }
  private apiUrl = environment.apiUrl;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  fetchPagePosts(pageId:any):Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/social-medias/api-posts-by-page/${pageId}`,this.httpOptions)
  }

  fetchPostsComments(postExternalId:any):Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/social-medias/api-comments-by--post/${postExternalId}`,this.httpOptions)
  }

}
